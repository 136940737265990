import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalLangService } from '../../services/global-lang.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  public loader = false;
  email: any;
  public form: FormGroup;
  lblforgotpasswordstatus: any;
  translate: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public api: ApiService,
    public globalservice: GlobalLangService
  ) {
    const language = this.globalservice.messageSource.value || localStorage.getItem('language');
    !language ? this.globalservice.messageSource.next('en') : this.globalservice.messageSource.next(language);
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])]
    });
    this.globalservice.languageDataObj.subscribe((data: any) => {
      if (data.LOGIN) {
        this.translate = data.FORGOTPASSWORD;
      }
    })
  }

  ngOnInit() {

  }

  forgotFun() {
    this.lblforgotpasswordstatus = '';
    const obj: any = {};
    obj.email = this.form.value.email;
    this.api.postData('academy/forgotPassword', obj)
      .subscribe(res => {
        if (res.statusCode == 200)
          //this.lblforgotpasswordstatus="Password Reset link has been sent to your email account. Please check your email.";
          this.api.custom_toastr_success_msg(this.translate.resetPasswordLinkSent);
        if (res.statusCode == 400)
          this.lblforgotpasswordstatus = res.message;
      },
      (error) => {console.log('error',error.error);
        if(error.error.responseType == 'emailNotFound')
        {
            this.api.custom_toastr_error_msg(this.translate.emailNotFound); 
        }
        if(error.error.responseType == 'emailNotverified')
        {
            this.api.custom_toastr_error_msg(this.translate.emailNotverified); 
        }
      });
   
  }
}
