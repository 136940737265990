import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalLangService } from 'src/app/services/global-lang.service';

@Component({
  selector: 'app-pause-subscriptions',
  templateUrl: './pause-subscriptions.component.html',
  styleUrls: ['./pause-subscriptions.component.css']
})
export class PauseSubscriptionsComponent implements OnInit {
  public translate: any;
  public pauseSubscriptionsForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PauseSubscriptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalLangService
  ) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      console.log("🚀 ~ PauseSubscriptionsComponent ~ this.globalService.languageDataObj.subscribe ~ data:", data)
      if (data.FILTERPLAYERS) {
        this.translate = data.FILTERPLAYERS;
      }
    });
  }

  ngOnInit() {
  }

  submitPlayer() {
    if (this.pauseSubscriptionsForm.valid) {
      this.dialogRef.close(this.pauseSubscriptionsForm.value);
    }
  }
  resetForm() {
    this.pauseSubscriptionsForm.reset();
    this.dialogRef.close(this.pauseSubscriptionsForm.value);
  }
  close() {
    this.dialogRef.close(); return false;
  }

}
