import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalLangService } from '../services/global-lang.service';

@Component({ 
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  disableAlert: boolean = false;
  thankyouPopup: boolean = false;
  deleteAcademy: boolean = false;
  adminDisableAlert: boolean = false;
  contractExpiredAlert: boolean = false;
  deleteAcademyName: any;
  translate: any;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalLangService) {
      this.globalService.languageDataObj.subscribe((data: any) => {
        if (data.INCOMPLETEPOPUP) {
          this.translate = data.INCOMPLETEPOPUP;
        }
      });
     }

  ngOnInit() {

    if(this.data.title == 'disableAlert' && !this.data.contractExpired){
      this.disableAlert = true ; 
    }
    if(this.data.title == 'thankyouPopup'){
      this.thankyouPopup = true; 
    }
    else if(this.data.title == 'deleteAcademy'){
      this.deleteAcademy = true ;
      this.deleteAcademyName = this.data.acdyName ;
    }
    /*if( this.data.signupStatus && !this.data.contractStatus){alert(2)
      this.adminDisableAlert = true;
      this.disableAlert = false ; 
    }*/
    if( this.data.signupStatus && !this.data.contractStatus && this.data.contractExpired){
      this.adminDisableAlert = false;
      this.disableAlert = false ; 
      this.contractExpiredAlert = true;
    }
  }

  completeReg(){    
    let data = {
      'completeReg' : true
    }
    this.dialogRef.close(data);
  }
  close(){
    let data = {
      'completeReg' : false
    }
    this.dialogRef.close(data);
  }

}
