import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import { GlobalLangService } from './services/global-lang.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ApiService } from './services/api.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    message;
    cssUrlAddress1: string = "/assets/acady-assets/encss/acadycustom.css";
    cssUrlAddress2: string = "/assets/acady-assets/encss/acadystyle.css";
    cssUrlAddress3: string = "/assets/acady-assets/encss/bootstrap.min.css";
    cssUrlAddress4: string = "/assets/acady-assets/encss/demo.css";
    cssUrlAddress5: string = "/assets/acady-assets/encss/style.css";

    // showModal = false;
    constructor(
        private api: ApiService, private swUpdate: SwUpdate,
        private zone: NgZone, location: PlatformLocation, public router: Router,
        private _location: Location, private applicationRef: ApplicationRef,
        private globalservice: GlobalLangService, public translate: TranslateService,
        public sanitizer: DomSanitizer,
        private titleService: Title
    ) {
        
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }
    }

    getUrl() {
        const len = this._location.path().indexOf(';');
        let str = this._location.path();
        if (len !== -1) {
            str = (this._location.path().slice(0, (len)));
        }
        return str;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

}
