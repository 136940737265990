import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalLangService {
  @Output() LanguageChange: EventEmitter<string> = new EventEmitter();
  languageValue = '';
  public messageSource = new BehaviorSubject('en');
  currentLanguage: any = this.messageSource.asObservable();
  private headerSource = new BehaviorSubject('false');
  currentVisual: any = this.headerSource.asObservable();
  languageDataObj =  new BehaviorSubject([]);
  constructor(public http: HttpClient,
    public api: ApiService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService) {
    const language = this.messageSource.value || localStorage.getItem('language');
    !language ? this.messageSource.next('ar') : this.messageSource.next(language);
    this.getLangObject();
    this.router.events.subscribe((ev) => {
    if (ev instanceof NavigationEnd) {
    if (localStorage.getItem('language') !== null) {
    const language = localStorage.getItem('language');
    if (language === 'en') {
    this.changeLanguageMethod('en');
    this.document.body.setAttribute('dir', 'ltr');
    } else {
    this.changeLanguageMethod('ar');
    this.currentLanguage.next('ar');
    this.document.body.setAttribute('dir', 'rtl');
    }
    }
    }
  });
  }
  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeLanguageMethod(value) {
    this.translate.use(value);
    if (value == 'ar') {
      document.body.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'ar');
      this.changeMessage('ar');
      localStorage.setItem('language', 'ar');
      //this.api.custom_toastr_success_msg('.تم تغيير اللغة');
    }
    if (value == 'en') {
      localStorage.setItem('language', 'en');
      document.body.removeAttribute('dir')
      this.changeMessage('en');
      //this.api.custom_toastr_success_msg('Language has been changed.');
    }
    this.languageValue = value;
    this.getLangObject();
    
  }

  changeHeaderVisual(message: string) {
    this.headerSource.next(message);
  }
  getLanguage() {
    return this.currentLanguage.value || localStorage.getItem('language');
  }
  async getLangObject() {
    let data: any;
    if ((this.currentLanguage.value || localStorage.getItem('language') === 'ar')) {
      data = await this.http.get("../../assets/i18n/ar.json").toPromise();
    } else if ((this.currentLanguage.value || localStorage.getItem('language') === 'en')) {
      data = await this.http.get("../../assets/i18n/en.json").toPromise();
    } else {
      data =await this.http.get("../../assets/i18n/ar.json").toPromise();
    }
   this.languageDataObj.next(data);
  }
}

