import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { GlobalLangService } from '../../services/global-lang.service';
import { TableCommonModel } from '../../shared/models/table.common.model';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  translate: any;
  myModel;
  isEnglish = false;
  loggedInUserType: string = '';
  notiType: string = 'all';
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<NotificationComponent>,
    public api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any, private globalService: GlobalLangService) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.HEADER) {
        this.translate = data.HEADER;
        this.myModel = new TableCommonModel();
      }
    });
    this.isEnglish = localStorage.getItem('language') === 'en' ? true : false;
  }

  ngOnInit() {
    this.loggedInUserType = localStorage.getItem('loggedInUserType');
    this.getList(this.notiType);
  }

  close() {
    this.dialogRef.close(); return false;
  }

  pageChanged(data) {
    this.myModel.currentPage = data.page;
    this.getList(this.notiType);
    return false;
  }

  getList(type) {
    this.notiType = type;
    let UsrIDVal = localStorage.getItem("UsrID");
    let usertype = localStorage.getItem("usertype");
    const obj: any = {};
    if (type == 'unread') {
      obj.unread = true;
    }
    obj.pageNo = this.myModel.currentPage;
    let apiURL: any;
    if (usertype == 'academyAdmin') {
      apiURL = this.api.apiUrl.getAdminNotification;
    }
    else {
      apiURL = this.api.apiUrl.getUserNotification;
    }
    this.api.getData(apiURL, obj, true)
      .subscribe(res => {
        this.myModel.allData = res.data.notifications;
        this.myModel.totalItems = res.data.count;
        if (res.data.count > 0 && res.data.count < 10) {
          //this.myAcademiesCount = '0'+res.data.count;
        }
        else {
          //this.myAcademiesCount = res.data.count;
        }
      },
        () => {
        });
  }

  timeDiff(timestamp) {
    let a = new Date().getTime();
    let b = new Date(timestamp).getTime();
    let diff: any = {};

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    diff.days = diff.hours / 24;
    diff.weeks = diff.days / 7;

    let obj: any = {};
    if (parseInt(diff.minutes) > 0 && parseInt(diff.minutes) <= 60 && parseInt(diff.hours) == 0) {
      obj.duration = parseInt(diff.minutes);
      obj.type = 'minutes';
    }
    else if (parseInt(diff.hours) > 0 && parseInt(diff.days) == 0) {
      obj.duration = parseInt(diff.hours);
      obj.type = 'hours';
    }
    else if (parseInt(diff.days) > 0) {
      obj.duration = parseInt(diff.days);
      obj.type = 'days';
    }
    return obj;
  }
}
