import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as constant from './constants';
import { apiUrl } from './apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';


@Injectable()
export class ApiService {

    constant = constant;
    apiUrl = apiUrl;
    noImage = '/assets/images/noImage.png';
    sidebarLogo = '/assets/images/Academy-Assets/placeholders_academy.png';
    thumbnailForNoImage: '/assets/images/placeholder1.jpg'
    userPlaceholder = '/assets/images/Academy-Assets/placeholder_image.png';
    coachPlaceholder = '/assets/images/placeholder1.jpg';
    placeholder = '/assets/images/placeholder.jpg';
    gif = '/assets/images/gif.gif';
    pdf = '/assets/images/pdf.jpeg';
    modalRef: BsModalRef;



    constructor(
        private router: Router,
        private http: HttpClient,
        public toastr: ToastrService,
        public lightBox: Lightbox,
        public modalService: BsModalService,
        private location: Location
    ) {
        this.apiEndpoint = environment.apiBaseUrl;
        this.AcademyapiEndpoint = environment.acadyapiBaseUrl;
        this.RMAcademyapiEndpoint = environment.RMapiBaseUrl;
        this.calenderViewStatus.next("current")
        this.calenderViewType.next("month")
    }

    public readonly apiEndpoint: String;
    public readonly AcademyapiEndpoint: String;
    public readonly RMAcademyapiEndpoint: String;
    private subject = new BehaviorSubject<any>(null);
    public title = this.subject.asObservable();
    public calenderViewStatus = new BehaviorSubject<any>('');
    public calenderViewType = new BehaviorSubject<any>('');

    private loaderSubject = new BehaviorSubject<any>(null);
    public loaderStatus = this.loaderSubject.asObservable();

    setTouched(form) {
        Object.keys(form.controls).forEach(key => {
            form.controls[key].markAsTouched({ onlySelf: true });
        });
    }

    getSelectedValues(arr, id) {
        let temp: any = [];
        arr.forEach((val) => {
            temp.push(val[id]);
        });
        return temp;
    }

    // show selected to dropdown match with backend
    selectedInArray(allvalues, selected) {
        let tempArr: any = [];
        allvalues.forEach((val) => {
            selected.forEach((val1) => {
                if (val._id === val1) {
                    tempArr.push(val);
                }
            });
        });
        return tempArr;
    }

    // show selected to dropdown match with backend
    selectedInArrayForSkills(allvalues, selected) {
        let tempArr: any = [];
        allvalues.forEach((val) => {
            // selected.forEach((val1) => {
            if (val._id === selected._id) {
                tempArr.push(val);
            }
            // });
        });
        return tempArr;
    }


    isFormValid(form) {
        if (form.valid) {
            return true;
        } else {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsTouched({ onlySelf: true });
            });
            return false;
        }

    }

    goBackFun() {
        this.location.back();
    }

    gotoUrl(obj) {
        this.router.navigate([], { queryParams: obj });
    }

    getDataFromFile(path) {
        return this.http.get(path);
    }

    navigate(url, params?) {
        if (params) {
            this.router.navigate([`/${url}`, params]);
        } else {
            this.router.navigate([`/${url}`]);
        }
    }

    changeTitle(title) {
        this.subject.next(title);
    }

    loaderOn(loaderStatus) {
        this.loaderSubject.next(loaderStatus);
    }

    removeEmptyKeys(obj) {
        Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
    }


    urltoFile(url, filename, mimeType) {
        const byteString = atob(url.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], filename, { 'type': mimeType });
    }

    getData(url, obj, loader?) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });
        return this.http.get<any>(this.apiEndpoint + url, { params: params, reportProgress: loader });
    }
    getSubscriptionData(url?) {
        return this.http.get<any>(this.apiEndpoint + url);
    }
    getDataNew(url, obj, loader) {
        return this.http.get<any>(this.apiEndpoint + url, { params: obj, reportProgress: loader });
    }

    postData(url, data) {
        return this.http.post<any>(this.apiEndpoint + url, data, { reportProgress: true });
    }
    postDataa(url, data) {
        return this.http.post<any>(url, data, { reportProgress: true });
    }

    postDataNew(url, obj, isLoading?: boolean) {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                formData.append(key, obj[key]);
            }
        });
        return this.http.post<any>(this.apiEndpoint + url, formData, { reportProgress: isLoading });
    }

    putData(url, data) {
        return this.http.put<any>(this.apiEndpoint + url, data, { reportProgress: true });
    }


    openLightBox(data) {
        if (data) {
            const temp: any = [];
            temp.push({
                src: data,
                thumb: data
            });

            this.lightBox.open(temp, 0);
            return true;
        }
    }

    uploadImage(url, file) {
        if (file && file.type.match('image.*')) {
            const formData = new FormData();
            formData.append('imageToUpload', file);
            return this.http.post<any>(this.apiEndpoint + url, formData);
        }
    }

    uploadVideo(url, formData) {
        return this.http.post<any>(this.apiEndpoint + url, formData, { reportProgress: true });
    }


    AcademyuploadVideo(url, formData) {
        //alert(this.AcademyapiEndpoint + url);
        // alert(formData);
        return this.http.post<any>(this.AcademyapiEndpoint + url, formData, { reportProgress: true });
    }

    getRatingData(url) {
        return this.http.get<any>(this.AcademyapiEndpoint + url, { reportProgress: true });
    }

    checkImage(file) {
        if (file) {
            if (file.size < 5000000) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    return true;
                } else {
                    this.toastr.error('Please add jpg or png image only');
                }
            } else {
                this.toastr.error('Please add image less than 5 MB');
            }
        }
        return false;
    }

    showModal(template, size, backdropType?: any) {
        if (size) {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: !backdropType ? 'static' : true,
                    keyboard: false,
                    class: `gray modal-${size}`
                }
            );
        } else {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false
                }
            );
        }
    }

    hideModal() {
        this.modalRef.hide();
    }

    blocked() {
        this.toastr.success('Blocked Successfully');
    }

    unblocked() {
        this.toastr.success('Unblocked Successfully');
    }
    locked() {
        this.toastr.success('Locked Successfully');
    }

    playersadded() {
        this.toastr.success('Player Successfully Added');
    }
    playersremoved() {
        this.toastr.success('Player Successfully Removed');
    }

    declined() {
        this.toastr.success('Successfully Declined');
    }
    approved() {
        this.toastr.success('Successfully Approved');
    }


    unlocked() {
        this.toastr.success('Unlocked Successfully');
    }

    favourited() {
        this.toastr.success('Marked As Favourite.');
    }

    unfavourited() {
        this.toastr.success('Remove from Favourite.');
    }

    updated() {
        this.toastr.success('Updated Successfully');
    }

    added() {
        this.toastr.success('Added Successfully');
    }

    deleted() {
        this.toastr.success('Deleted Successfully');
    }
    rejected() {
        this.toastr.success(' Sucessfully Rejected');
    }

    accepted() {
        this.toastr.success(' Successfully Accepted');
    }

    custom_toastr_success_msg(msgdata) {
        this.toastr.success(msgdata);
    }
    custom_toastr_error_msg(msgdata) {
        this.toastr.error(msgdata);
    }
    //Acadmy ------------------------------------------------------Begin
    AcademyPutData(url, data) {
        return this.http.post<any>(this.AcademyapiEndpoint + url, data, { reportProgress: true });
    }

    AcademyPostData(url, data, loader) {
        return this.http.post<any>(this.AcademyapiEndpoint + url, data, { reportProgress: true });
    }

    AcademyGetData(url, obj, loader?) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });

        return this.http.get<any>(this.AcademyapiEndpoint + url, { params: params, reportProgress: loader });
    }
    liveAcademyGetData(url, obj, loader?) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });

        return this.http.get<any>(url, { params: params, reportProgress: loader });
    }


    //RM----------Acadmy  ------------------------------------------------------Begin
    RMAcademyPutData(url, data) {
        return this.http.post<any>(this.AcademyapiEndpoint + url, data, { reportProgress: true });
    }
    RMAcademyPostData(url, data, loader) {

        return this.http.post<any>(this.AcademyapiEndpoint + url, data, { reportProgress: true });
    }

    RMAcademyGetData(url, obj, loader?) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });

        return this.http.get<any>(this.AcademyapiEndpoint + url, { params: params, reportProgress: loader });
    }
    getCurrencies(url) {
        return this.http.get<any>(this.RMAcademyapiEndpoint + url);
    }
    getSessionList(url) {
        return this.http.get<any>(this.apiEndpoint + url);
    }
    countryNameList = [
        { countryCode: "+93", countryName: "AF +93", countryFlag: '../assets/svg-country-flags/svg/af.svg' },
        { countryCode: "+358", countryName: "AX +358", countryFlag: '../assets/svg-country-flags/svg/ax.svg' },
        { countryCode: "+355", countryName: "AL +355", countryFlag: '../assets/svg-country-flags/svg/al.svg' },
        { countryCode: "+213", countryName: "DZ +213", countryFlag: '../assets/svg-country-flags/svg/dz.svg' },
        { countryCode: "+1684", countryName: "AS +1684", countryFlag: '../assets/svg-country-flags/svg/as.svg' },
        { countryCode: "+376", countryName: "AD +376", countryFlag: '../assets/svg-country-flags/svg/ad.svg' },
        { countryCode: "+244", countryName: "AO +244", countryFlag: '../assets/svg-country-flags/svg/ao.svg' },
        { countryCode: "+1264", countryName: "AI +1264", countryFlag: '../assets/svg-country-flags/svg/ai.svg' },
        { countryCode: "+672", countryName: "AQ +672", countryFlag: '../assets/svg-country-flags/svg/aq.svg' },
        { countryCode: "+1268", countryName: "AG +1268", countryFlag: '../assets/svg-country-flags/svg/ag.svg' },
        { countryCode: "+54", countryName: "AR +54", countryFlag: '../assets/svg-country-flags/svg/ar.svg' },
        { countryCode: "+374", countryName: "AM +374", countryFlag: '../assets/svg-country-flags/svg/am.svg' },
        { countryCode: "+297", countryName: "AW +297", countryFlag: '../assets/svg-country-flags/svg/aw.svg' },
        { countryCode: "+61", countryName: "AU +61", countryFlag: '../assets/svg-country-flags/svg/au.svg' },
        { countryCode: "+43", countryName: "AT +43", countryFlag: '../assets/svg-country-flags/svg/at.svg' },
        { countryCode: "+994", countryName: "AZ +994", countryFlag: '../assets/svg-country-flags/svg/az.svg' },
        { countryCode: "+1242", countryName: "BS +1242", countryFlag: '../assets/svg-country-flags/svg/bs.svg' },
        { countryCode: "+973", countryName: "BH +973", countryFlag: '../assets/svg-country-flags/svg/bh.svg' },
        { countryCode: "+880", countryName: "BD +880", countryFlag: '../assets/svg-country-flags/svg/bd.svg' },
        { countryCode: "+1246", countryName: "BB +1246", countryFlag: '../assets/svg-country-flags/svg/bb.svg' },
        { countryCode: "+375", countryName: "BY +375", countryFlag: '../assets/svg-country-flags/svg/by.svg' },
        { countryCode: "+32", countryName: "BE +32", countryFlag: '../assets/svg-country-flags/svg/be.svg' },
        { countryCode: "+501", countryName: "BZ +501", countryFlag: '../assets/svg-country-flags/svg/bz.svg' },
        { countryCode: "+229", countryName: "BJ +229", countryFlag: '../assets/svg-country-flags/svg/bj.svg' },
        { countryCode: "+1441", countryName: "BM +1441", countryFlag: '../assets/svg-country-flags/svg/bm.svg' },
        { countryCode: "+975", countryName: "BT +975", countryFlag: '../assets/svg-country-flags/svg/bt.svg' },
        { countryCode: "+591", countryName: "BO +591", countryFlag: '../assets/svg-country-flags/svg/bo.svg' },
        { countryCode: "+387", countryName: "BA +387", countryFlag: '../assets/svg-country-flags/svg/ba.svg' },
        { countryCode: "+267", countryName: "BW +267", countryFlag: '../assets/svg-country-flags/svg/bw.svg' },
        { countryCode: "+47", countryName: "BV +47", countryFlag: '../assets/svg-country-flags/svg/bv.svg' },
        { countryCode: "+55", countryName: "BR +55", countryFlag: '../assets/svg-country-flags/svg/br.svg' },
        { countryCode: "+673", countryName: "BN +673", countryFlag: '../assets/svg-country-flags/svg/bn.svg' },
        { countryCode: "+359", countryName: "BG +359", countryFlag: '../assets/svg-country-flags/svg/bg.svg' },
        { countryCode: "+226", countryName: "BF +226", countryFlag: '../assets/svg-country-flags/svg/bf.svg' },
        { countryCode: "+257", countryName: "BI +257", countryFlag: '../assets/svg-country-flags/svg/bi.svg' },
        { countryCode: "+855", countryName: "KH +855", countryFlag: '../assets/svg-country-flags/svg/kh.svg' },
        { countryCode: "+237", countryName: "🇨🇲 +237", countryFlag: '../assets/svg-country-flags/svg/cm.svg' },
        { countryCode: "+1", countryName: "CA +1", countryFlag: '../assets/svg-country-flags/svg/ca.svg' },
        { countryCode: "+238", countryName: "CV +238", countryFlag: '../assets/svg-country-flags/svg/cv.svg' },
        { countryCode: "+345", countryName: "KY +345", countryFlag: '../assets/svg-country-flags/svg/ky.svg' },
        { countryCode: "+236", countryName: "CF +236", countryFlag: '../assets/svg-country-flags/svg/cf.svg' },
        { countryCode: "+235", countryName: "TD +235", countryFlag: '../assets/svg-country-flags/svg/td.svg' },
        { countryCode: "+56", countryName: "CL +56", countryFlag: '../assets/svg-country-flags/svg/cl.svg' },
        { countryCode: "+86", countryName: "CN +86", countryFlag: '../assets/svg-country-flags/svg/cn.svg' },
        { countryCode: "+61", countryName: "CX +61", countryFlag: '../assets/svg-country-flags/svg/cx.svg' },
        { countryCode: "+61", countryName: "CC +61", countryFlag: '../assets/svg-country-flags/svg/cc.svg' },
        { countryCode: "+57", countryName: "CO +57", countryFlag: '../assets/svg-country-flags/svg/co.svg' },
        { countryCode: "+269", countryName: "KM +269", countryFlag: '../assets/svg-country-flags/svg/km.svg' },
        { countryCode: "+242", countryName: "CG +242", countryFlag: '../assets/svg-country-flags/svg/cg.svg' },
        { countryCode: "+243", countryName: "CD +243", countryFlag: '../assets/svg-country-flags/svg/cd.svg' },
        { countryCode: "+682", countryName: "CK +682", countryFlag: '../assets/svg-country-flags/svg/ck.svg' },
        { countryCode: "+506", countryName: "CR +506", countryFlag: '../assets/svg-country-flags/svg/cr.svg' },
        { countryCode: "+225", countryName: "CI +225", countryFlag: '../assets/svg-country-flags/svg/ci.svg' },
        { countryCode: "+385", countryName: "HR +385", countryFlag: '../assets/svg-country-flags/svg/hr.svg' },
        { countryCode: "+53", countryName: "CU +53", countryFlag: '../assets/svg-country-flags/svg/cu.svg' },
        { countryCode: "+357", countryName: "CY +357", countryFlag: '../assets/svg-country-flags/svg/cy.svg' },
        { countryCode: "+420", countryName: "CZ +420", countryFlag: '../assets/svg-country-flags/svg/cz.svg' },
        { countryCode: "+45", countryName: "DK +45", countryFlag: '../assets/svg-country-flags/svg/dk.svg' },
        { countryCode: "+253", countryName: "DJ +253", countryFlag: '../assets/svg-country-flags/svg/dj.svg' },
        { countryCode: "+1767", countryName: "DM +1767", countryFlag: '../assets/svg-country-flags/svg/dm.svg' },
        { countryCode: "+1849", countryName: "DO +1849", countryFlag: '../assets/svg-country-flags/svg/do.svg' },
        { countryCode: "+593", countryName: "EC +593", countryFlag: '../assets/svg-country-flags/svg/ec.svg' },
        { countryCode: "+20", countryName: "EG +20", countryFlag: '../assets/svg-country-flags/svg/eg.svg' },
        { countryCode: "+503", countryName: "SV +503", countryFlag: '../assets/svg-country-flags/svg/sv.svg' },
        { countryCode: "+240", countryName: "GQ +240", countryFlag: '../assets/svg-country-flags/svg/gq.svg' },
        { countryCode: "+291", countryName: "ER +291", countryFlag: '../assets/svg-country-flags/svg/er.svg' },
        { countryCode: "+372", countryName: "EE +372", countryFlag: '../assets/svg-country-flags/svg/ee.svg' },
        { countryCode: "+251", countryName: "ET +251", countryFlag: '../assets/svg-country-flags/svg/et.svg' },
        { countryCode: "+500", countryName: "FK +500", countryFlag: '../assets/svg-country-flags/svg/fk.svg' },
        { countryCode: "+298", countryName: "FO +298", countryFlag: '../assets/svg-country-flags/svg/fo.svg' },
        { countryCode: "+679", countryName: "FJ +679", countryFlag: '../assets/svg-country-flags/svg/fj.svg' },
        { countryCode: "+358", countryName: "FI +358", countryFlag: '../assets/svg-country-flags/svg/fi.svg' },
        { countryCode: "+33", countryName: "FR +33", countryFlag: '../assets/svg-country-flags/svg/fr.svg' },
        { countryCode: "+594", countryName: "GF +594", countryFlag: '../assets/svg-country-flags/svg/gf.svg' },
        { countryCode: "+689", countryName: "PF +689", countryFlag: '../assets/svg-country-flags/svg/pf.svg' },
        { countryCode: "+262", countryName: "TF +262", countryFlag: '../assets/svg-country-flags/svg/tf.svg' },
        { countryCode: "+241", countryName: "GA +241", countryFlag: '../assets/svg-country-flags/svg/ga.svg' },
        { countryCode: "+220", countryName: "GM +220", countryFlag: '../assets/svg-country-flags/svg/gm.svg' },
        { countryCode: "+995", countryName: "GE +995", countryFlag: '../assets/svg-country-flags/svg/ge.svg' },
        { countryCode: "+49", countryName: "DE +49", countryFlag: '../assets/svg-country-flags/svg/de.svg' },
        { countryCode: "+233", countryName: "GH +233", countryFlag: '../assets/svg-country-flags/svg/gh.svg' },
        { countryCode: "+350", countryName: "GI +350", countryFlag: '../assets/svg-country-flags/svg/gi.svg' },
        { countryCode: "+30", countryName: "GR +30", countryFlag: '../assets/svg-country-flags/svg/gr.svg' },
        { countryCode: "+299", countryName: "GL +299", countryFlag: '../assets/svg-country-flags/svg/gl.svg' },
        { countryCode: "+1473", countryName: "GD +1473", countryFlag: '../assets/svg-country-flags/svg/gd.svg' },
        { countryCode: "+590", countryName: "GP +590", countryFlag: '../assets/svg-country-flags/svg/gp.svg' },
        { countryCode: "+1671", countryName: "GU +1671", countryFlag: '../assets/svg-country-flags/svg/gu.svg' },
        { countryCode: "+502", countryName: "GT +502", countryFlag: '../assets/svg-country-flags/svg/gt.svg' },
        { countryCode: "+44", countryName: "GG +44", countryFlag: '../assets/svg-country-flags/svg/gg.svg' },
        { countryCode: "+224", countryName: "GN +224", countryFlag: '../assets/svg-country-flags/svg/gn.svg' },
        { countryCode: "+245", countryName: "GW +245", countryFlag: '../assets/svg-country-flags/svg/gw.svg' },
        { countryCode: "+592", countryName: "GY +592", countryFlag: '../assets/svg-country-flags/svg/gy.svg' },
        { countryCode: "+509", countryName: "HT +509", countryFlag: '../assets/svg-country-flags/svg/ht.svg' },
        { countryCode: "+672", countryName: "HM +672", countryFlag: '../assets/svg-country-flags/svg/hm.svg' },
        { countryCode: "+379", countryName: "VA +379", countryFlag: '../assets/svg-country-flags/svg/va.svg' },
        { countryCode: "+504", countryName: "HN +504", countryFlag: '../assets/svg-country-flags/svg/hn.svg' },
        { countryCode: "+852", countryName: "HK +852", countryFlag: '../assets/svg-country-flags/svg/hk.svg' },
        { countryCode: "+36", countryName: "HU +36", countryFlag: '../assets/svg-country-flags/svg/hu.svg' },
        { countryCode: "+354", countryName: "IS +354", countryFlag: '../assets/svg-country-flags/svg/is.svg' },
        { countryCode: "+91", countryName: "IN +91", countryFlag: '../assets/svg-country-flags/svg/in.svg' },
        { countryCode: "+62", countryName: "ID +62", countryFlag: '../assets/svg-country-flags/svg/id.svg' },
        { countryCode: "+98", countryName: "IR +98", countryFlag: '../assets/svg-country-flags/svg/ir.svg' },
        { countryCode: "+964", countryName: "IQ +964", countryFlag: '../assets/svg-country-flags/svg/iq.svg' },
        { countryCode: "+353", countryName: "IE +353", countryFlag: '../assets/svg-country-flags/svg/ie.svg' },
        { countryCode: "+44", countryName: "IM +44", countryFlag: '../assets/svg-country-flags/svg/im.svg' },
        { countryCode: "+246", countryName: "IO +246", countryFlag: '../assets/svg-country-flags/svg/io.svg' },
        // { countryCode: "+972", countryName: "🇮🇱 +972" , countryFlag : '../assets/svg-country-flags/svg/il.svg' },
        { countryCode: "+39", countryName: "IT +39", countryFlag: '../assets/svg-country-flags/svg/it.svg' },
        { countryCode: "+1876", countryName: "JM +1876", countryFlag: '../assets/svg-country-flags/svg/jm.svg' },
        { countryCode: "+81", countryName: "JP +81", countryFlag: '../assets/svg-country-flags/svg/jp.svg' },
        { countryCode: "+44", countryName: "JE +44", countryFlag: '../assets/svg-country-flags/svg/je.svg' },
        { countryCode: "+962", countryName: "JO +962", countryFlag: '../assets/svg-country-flags/svg/jo.svg' },
        { countryCode: "+7", countryName: "KZ +7", countryFlag: '../assets/svg-country-flags/svg/kz.svg' },
        { countryCode: "+254", countryName: "KE +254", countryFlag: '../assets/svg-country-flags/svg/ke.svg' },
        { countryCode: "+686", countryName: "KI +686", countryFlag: '../assets/svg-country-flags/svg/ki.svg' },
        { countryCode: "+850", countryName: "KP +850", countryFlag: '../assets/svg-country-flags/svg/kp.svg' },
        { countryCode: "+82", countryName: "KR +82", countryFlag: '../assets/svg-country-flags/svg/kr.svg' },
        { countryCode: "+383", countryName: "XK +383", countryFlag: '../assets/svg-country-flags/svg/xk.svg' },
        { countryCode: "+965", countryName: "KW +965", countryFlag: '../assets/svg-country-flags/svg/kw.svg' },
        { countryCode: "+996", countryName: "KG +996", countryFlag: '../assets/svg-country-flags/svg/kg.svg' },
        { countryCode: "+856", countryName: "LA +856", countryFlag: '../assets/svg-country-flags/svg/la.svg' },
        { countryCode: "+371", countryName: "LV +371", countryFlag: '../assets/svg-country-flags/svg/lv.svg' },
        { countryCode: "+961", countryName: "LB +961", countryFlag: '../assets/svg-country-flags/svg/lb.svg' },
        { countryCode: "+266", countryName: "LS +266", countryFlag: '../assets/svg-country-flags/svg/ls.svg' },
        { countryCode: "+231", countryName: "LR +231", countryFlag: '../assets/svg-country-flags/svg/lr.svg' },
        { countryCode: "+218", countryName: "LY +218", countryFlag: '../assets/svg-country-flags/svg/ly.svg' },
        { countryCode: "+423", countryName: "LI +423", countryFlag: '../assets/svg-country-flags/svg/li.svg' },
        { countryCode: "+370", countryName: "LT +370", countryFlag: '../assets/svg-country-flags/svg/lt.svg' },
        { countryCode: "+352", countryName: "LU +352", countryFlag: '../assets/svg-country-flags/svg/lu.svg' },
        { countryCode: "+853", countryName: "MO +853", countryFlag: '../assets/svg-country-flags/svg/mo.svg' },
        { countryCode: "+389", countryName: "MK +389", countryFlag: '../assets/svg-country-flags/svg/mk.svg' },
        { countryCode: "+261", countryName: "MG +261", countryFlag: '../assets/svg-country-flags/svg/mg.svg' },
        { countryCode: "+265", countryName: "MW +265", countryFlag: '../assets/svg-country-flags/svg/mw.svg' },
        { countryCode: "+60", countryName: "MY +60", countryFlag: '../assets/svg-country-flags/svg/my.svg' },
        { countryCode: "+960", countryName: "MV +960", countryFlag: '../assets/svg-country-flags/svg/mv.svg' },
        { countryCode: "+223", countryName: "ML +223", countryFlag: '../assets/svg-country-flags/svg/ml.svg' },
        { countryCode: "+356", countryName: "MT +356", countryFlag: '../assets/svg-country-flags/svg/mt.svg' },
        { countryCode: "+692", countryName: "MH +692", countryFlag: '../assets/svg-country-flags/svg/mh.svg' },
        { countryCode: "+596", countryName: "MQ +596", countryFlag: '../assets/svg-country-flags/svg/mq.svg' },
        { countryCode: "+222", countryName: "MR +222", countryFlag: '../assets/svg-country-flags/svg/mr.svg' },
        { countryCode: "+230", countryName: "MU +230", countryFlag: '../assets/svg-country-flags/svg/mu.svg' },
        { countryCode: "+262", countryName: "YT +262", countryFlag: '../assets/svg-country-flags/svg/yt.svg' },
        { countryCode: "+52", countryName: "MX +52", countryFlag: '../assets/svg-country-flags/svg/mx.svg' },
        { countryCode: "+691", countryName: "FM +691", countryFlag: '../assets/svg-country-flags/svg/fm.svg' },
        { countryCode: "+373", countryName: "MD +373", countryFlag: '../assets/svg-country-flags/svg/md.svg' },
        { countryCode: "+377", countryName: "MC +377", countryFlag: '../assets/svg-country-flags/svg/mc.svg' },
        { countryCode: "+976", countryName: "MN +976", countryFlag: '../assets/svg-country-flags/svg/mn.svg' },
        { countryCode: "+382", countryName: "ME +382", countryFlag: '../assets/svg-country-flags/svg/me.svg' },
        { countryCode: "+1664", countryName: "MS +1664", countryFlag: '../assets/svg-country-flags/svg/ms.svg' },
        { countryCode: "+212", countryName: "MA +212", countryFlag: '../assets/svg-country-flags/svg/ma.svg' },
        { countryCode: "+258", countryName: "MZ +258", countryFlag: '../assets/svg-country-flags/svg/mz.svg' },
        { countryCode: "+95", countryName: "MM +95", countryFlag: '../assets/svg-country-flags/svg/mm.svg' },
        { countryCode: "+264", countryName: "NA +264", countryFlag: '../assets/svg-country-flags/svg/na.svg' },
        { countryCode: "+674", countryName: "NR +674", countryFlag: '../assets/svg-country-flags/svg/nr.svg' },
        { countryCode: "+977", countryName: "NP +977", countryFlag: '../assets/svg-country-flags/svg/np.svg' },
        { countryCode: "+31", countryName: "NL +31", countryFlag: '../assets/svg-country-flags/svg/nl.svg' },
        { countryCode: "+599", countryName: "NA +599", countryFlag: '../assets/svg-country-flags/svg/na.svg' },
        { countryCode: "+687", countryName: "NC +687", countryFlag: '../assets/svg-country-flags/svg/nc.svg' },
        { countryCode: "+64", countryName: "NZ +64", countryFlag: '../assets/svg-country-flags/svg/nz.svg' },
        { countryCode: "+505", countryName: "NI +505", countryFlag: '../assets/svg-country-flags/svg/ni.svg' },
        { countryCode: "+227", countryName: "NE +227", countryFlag: '../assets/svg-country-flags/svg/ne.svg' },
        { countryCode: "+234", countryName: "NG +234", countryFlag: '../assets/svg-country-flags/svg/ng.svg' },
        { countryCode: "+683", countryName: "NU +683", countryFlag: '../assets/svg-country-flags/svg/nu.svg' },
        { countryCode: "+672", countryName: "NF +672", countryFlag: '../assets/svg-country-flags/svg/nf.svg' },
        { countryCode: "+1670", countryName: "MP +1670", countryFlag: '../assets/svg-country-flags/svg/mp.svg' },
        { countryCode: "+47", countryName: "NO +47", countryFlag: '../assets/svg-country-flags/svg/no.svg' },
        { countryCode: "+968", countryName: "OM +968", countryFlag: '../assets/svg-country-flags/svg/om.svg' },
        { countryCode: "+92", countryName: "PK +92", countryFlag: '../assets/svg-country-flags/svg/pk.svg' },
        { countryCode: "+680", countryName: "PW +680", countryFlag: '../assets/svg-country-flags/svg/pw.svg' },
        { countryCode: "+970", countryName: "PS +970", countryFlag: '../assets/svg-country-flags/svg/ps.svg' },
        { countryCode: "+507", countryName: "PA +507", countryFlag: '../assets/svg-country-flags/svg/pa.svg' },
        { countryCode: "+675", countryName: "PG +675", countryFlag: '../assets/svg-country-flags/svg/pg.svg' },
        { countryCode: "+595", countryName: "PY +595", countryFlag: '../assets/svg-country-flags/svg/py.svg' },
        { countryCode: "+51", countryName: "PE +51", countryFlag: '../assets/svg-country-flags/svg/pe.svg' },
        { countryCode: "+63", countryName: "PH +63", countryFlag: '../assets/svg-country-flags/svg/ph.svg' },
        { countryCode: "+64", countryName: "PN +64", countryFlag: '../assets/svg-country-flags/svg/pn.svg' },
        { countryCode: "+48", countryName: "PL +48", countryFlag: '../assets/svg-country-flags/svg/pl.svg' },
        { countryCode: "+351", countryName: "PT +351", countryFlag: '../assets/svg-country-flags/svg/pt.svg' },
        { countryCode: "+1939", countryName: "PR +1939", countryFlag: '../assets/svg-country-flags/svg/pr.svg' },
        { countryCode: "+974", countryName: "QA +974", countryFlag: '../assets/svg-country-flags/svg/qa.svg' },
        { countryCode: "+40", countryName: "RO +40", countryFlag: '../assets/svg-country-flags/svg/ro.svg' },
        { countryCode: "+7", countryName: "RU +7", countryFlag: '../assets/svg-country-flags/svg/ru.svg' },
        { countryCode: "+250", countryName: "RW +250", countryFlag: '../assets/svg-country-flags/svg/rw.svg' },
        { countryCode: "+262", countryName: "RE +262", countryFlag: '../assets/svg-country-flags/svg/re.svg' },
        { countryCode: "+590", countryName: "BL +590", countryFlag: '../assets/svg-country-flags/svg/bl.svg' },
        { countryCode: "+290", countryName: "SH +290", countryFlag: '../assets/svg-country-flags/svg/sh.svg' },
        { countryCode: "+1869", countryName: "KN +1869", countryFlag: '../assets/svg-country-flags/svg/kn.svg' },
        { countryCode: "+1758", countryName: "LC +1758", countryFlag: '../assets/svg-country-flags/svg/lc.svg' },
        { countryCode: "+590", countryName: "MF +590", countryFlag: '../assets/svg-country-flags/svg/mf.svg' },
        { countryCode: "+508", countryName: "PM +508", countryFlag: '../assets/svg-country-flags/svg/pm.svg' },
        { countryCode: "+1784", countryName: "VC +1784", countryFlag: '../assets/svg-country-flags/svg/vc.svg' },
        { countryCode: "+685", countryName: "WS +685", countryFlag: '../assets/svg-country-flags/svg/ws.svg' },
        { countryCode: "+378", countryName: "SM +378", countryFlag: '../assets/svg-country-flags/svg/sm.svg' },
        { countryCode: "+239", countryName: "ST +239", countryFlag: '../assets/svg-country-flags/svg/st.svg' },
        { countryCode: "+966", countryName: "SA +966", countryFlag: '../assets/svg-country-flags/svg/sa.svg' },
        { countryCode: "+221", countryName: "SN +221", countryFlag: '../assets/svg-country-flags/svg/sn.svg' },
        { countryCode: "+381", countryName: "RS +381", countryFlag: '../assets/svg-country-flags/svg/rs.svg' },
        { countryCode: "+248", countryName: "SC +248", countryFlag: '../assets/svg-country-flags/svg/sc.svg' },
        { countryCode: "+232", countryName: "SL +232", countryFlag: '../assets/svg-country-flags/svg/sl.svg' },
        { countryCode: "+65", countryName: "SG +65", countryFlag: '../assets/svg-country-flags/svg/sg.svg' },
        { countryCode: "+421", countryName: "SK +421", countryFlag: '../assets/svg-country-flags/svg/sk.svg' },
        { countryCode: "+386", countryName: "SI +386", countryFlag: '../assets/svg-country-flags/svg/si.svg' },
        { countryCode: "+677", countryName: "SB +677", countryFlag: '../assets/svg-country-flags/svg/sb.svg' },
        { countryCode: "+252", countryName: "SO +252", countryFlag: '../assets/svg-country-flags/svg/so.svg' },
        { countryCode: "+27", countryName: "ZA +27", countryFlag: '../assets/svg-country-flags/svg/za.svg' },
        { countryCode: "+211", countryName: "SS +211", countryFlag: '../assets/svg-country-flags/svg/ss.svg' },
        { countryCode: "+500", countryName: "GS +500", countryFlag: '../assets/svg-country-flags/svg/gs.svg' },
        { countryCode: "+34", countryName: "ES +34", countryFlag: '../assets/svg-country-flags/svg/es.svg' },
        { countryCode: "+94", countryName: "LK +94", countryFlag: '../assets/svg-country-flags/svg/lk.svg' },
        { countryCode: "+249", countryName: "SD +249", countryFlag: '../assets/svg-country-flags/svg/sd.svg' },
        { countryCode: "+597", countryName: "SR +597", countryFlag: '../assets/svg-country-flags/svg/sr.svg' },
        { countryCode: "+47", countryName: "SJ +47", countryFlag: '../assets/svg-country-flags/svg/sj.svg' },
        { countryCode: "+268", countryName: "SZ +268", countryFlag: '../assets/svg-country-flags/svg/sz.svg' },
        { countryCode: "+46", countryName: "SE +46", countryFlag: '../assets/svg-country-flags/svg/se.svg' },
        { countryCode: "+41", countryName: "CH +41", countryFlag: '../assets/svg-country-flags/svg/ch.svg' },
        { countryCode: "+963", countryName: "SY +963", countryFlag: '../assets/svg-country-flags/svg/sy.svg' },
        { countryCode: "+886", countryName: "TW +886", countryFlag: '../assets/svg-country-flags/svg/tw.svg' },
        { countryCode: "+992", countryName: "TJ +992", countryFlag: '../assets/svg-country-flags/svg/tj.svg' },
        { countryCode: "+255", countryName: "TZ +255", countryFlag: '../assets/svg-country-flags/svg/tz.svg' },
        { countryCode: "+66", countryName: "TH +66", countryFlag: '../assets/svg-country-flags/svg/th.svg' },
        { countryCode: "+670", countryName: "TL +670", countryFlag: '../assets/svg-country-flags/svg/tl.svg' },
        { countryCode: "+228", countryName: "TG +228", countryFlag: '../assets/svg-country-flags/svg/tg.svg' },
        { countryCode: "+690", countryName: "TK +690", countryFlag: '../assets/svg-country-flags/svg/tk.svg' },
        { countryCode: "+676", countryName: "TO +676", countryFlag: '../assets/svg-country-flags/svg/to.svg' },
        { countryCode: "+1868", countryName: "TT +1868", countryFlag: '../assets/svg-country-flags/svg/tt.svg' },
        { countryCode: "+216", countryName: "TN +216", countryFlag: '../assets/svg-country-flags/svg/tn.svg' },
        { countryCode: "+90", countryName: "TR +90", countryFlag: '../assets/svg-country-flags/svg/tr.svg' },
        { countryCode: "+993", countryName: "TM +993", countryFlag: '../assets/svg-country-flags/svg/tm.svg' },
        { countryCode: "+1649", countryName: "TC +1649", countryFlag: '../assets/svg-country-flags/svg/tc.svg' },
        { countryCode: "+688", countryName: "TV +688", countryFlag: '../assets/svg-country-flags/svg/tv.svg' },
        { countryCode: "+256", countryName: "UG +256", countryFlag: '../assets/svg-country-flags/svg/ug.svg' },
        { countryCode: "+380", countryName: "UA +380", countryFlag: '../assets/svg-country-flags/svg/ua.svg' },
        { countryCode: "+971", countryName: "AE +971", countryFlag: '../assets/svg-country-flags/svg/ae.svg' },
        { countryCode: "+44", countryName: "GB +44", countryFlag: '../assets/svg-country-flags/svg/gb.svg' },
        { countryCode: "+1", countryName: "US +1", countryFlag: '../assets/svg-country-flags/svg/us.svg' },
        { countryCode: "+598", countryName: "UY +598", countryFlag: '../assets/svg-country-flags/svg/uy.svg' },
        { countryCode: "+998", countryName: "UZ +998", countryFlag: '../assets/svg-country-flags/svg/uz.svg' },
        { countryCode: "+678", countryName: "VU +678", countryFlag: '../assets/svg-country-flags/svg/vu.svg' },
        { countryCode: "+58", countryName: "VE +58", countryFlag: '../assets/svg-country-flags/svg/ve.svg' },
        { countryCode: "+84", countryName: "VN +84", countryFlag: '../assets/svg-country-flags/svg/vn.svg' },
        { countryCode: "+1284", countryName: "VG +1284", countryFlag: '../assets/svg-country-flags/svg/vg.svg' },
        { countryCode: "+1340", countryName: "VI +1340", countryFlag: '../assets/svg-country-flags/svg/vi.svg' },
        { countryCode: "+681", countryName: "WF +681", countryFlag: '../assets/svg-country-flags/svg/wf.svg' },
        { countryCode: "+967", countryName: "YE +967", countryFlag: '../assets/svg-country-flags/svg/ye.svg' },
        { countryCode: "+260", countryName: "ZM +260", countryFlag: '../assets/svg-country-flags/svg/zm.svg' },
        { countryCode: "+263", countryName: "ZW +263", countryFlag: '../assets/svg-country-flags/svg/zw.svg' },
    ];

    nationalities = [
        [
            {
                "code": "AD",
                "emoji": "🇦🇩",
                "unicode": "U+1F1E6 U+1F1E9",
                "name": "Andorra",
                "title": "flag for Andorra"
            },
            {
                "code": "AE",
                "emoji": "🇦🇪",
                "unicode": "U+1F1E6 U+1F1EA",
                "name": "United Arab Emirates",
                "title": "flag for United Arab Emirates"
            },
            {
                "code": "AF",
                "emoji": "🇦🇫",
                "unicode": "U+1F1E6 U+1F1EB",
                "name": "Afghanistan",
                "title": "flag for Afghanistan"
            },
            {
                "code": "AG",
                "emoji": "🇦🇬",
                "unicode": "U+1F1E6 U+1F1EC",
                "name": "Antigua and Barbuda",
                "title": "flag for Antigua and Barbuda"
            },
            {
                "code": "AI",
                "emoji": "🇦🇮",
                "unicode": "U+1F1E6 U+1F1EE",
                "name": "Anguilla",
                "title": "flag for Anguilla"
            },
            {
                "code": "AL",
                "emoji": "🇦🇱",
                "unicode": "U+1F1E6 U+1F1F1",
                "name": "Albania",
                "title": "flag for Albania"
            },
            {
                "code": "AM",
                "emoji": "🇦🇲",
                "unicode": "U+1F1E6 U+1F1F2",
                "name": "Armenia",
                "title": "flag for Armenia"
            },
            {
                "code": "AO",
                "emoji": "🇦🇴",
                "unicode": "U+1F1E6 U+1F1F4",
                "name": "Angola",
                "title": "flag for Angola"
            },
            {
                "code": "AQ",
                "emoji": "🇦🇶",
                "unicode": "U+1F1E6 U+1F1F6",
                "name": "Antarctica",
                "title": "flag for Antarctica"
            },
            {
                "code": "AR",
                "emoji": "🇦🇷",
                "unicode": "U+1F1E6 U+1F1F7",
                "name": "Argentina",
                "title": "flag for Argentina"
            },
            {
                "code": "AS",
                "emoji": "🇦🇸",
                "unicode": "U+1F1E6 U+1F1F8",
                "name": "American Samoa",
                "title": "flag for American Samoa"
            },
            {
                "code": "AT",
                "emoji": "🇦🇹",
                "unicode": "U+1F1E6 U+1F1F9",
                "name": "Austria",
                "title": "flag for Austria"
            },
            {
                "code": "AU",
                "emoji": "🇦🇺",
                "unicode": "U+1F1E6 U+1F1FA",
                "name": "Australia",
                "title": "flag for Australia"
            },
            {
                "code": "AW",
                "emoji": "🇦🇼",
                "unicode": "U+1F1E6 U+1F1FC",
                "name": "Aruba",
                "title": "flag for Aruba"
            },
            {
                "code": "AX",
                "emoji": "🇦🇽",
                "unicode": "U+1F1E6 U+1F1FD",
                "name": "Åland Islands",
                "title": "flag for Åland Islands"
            },
            {
                "code": "AZ",
                "emoji": "🇦🇿",
                "unicode": "U+1F1E6 U+1F1FF",
                "name": "Azerbaijan",
                "title": "flag for Azerbaijan"
            },
            {
                "code": "BA",
                "emoji": "🇧🇦",
                "unicode": "U+1F1E7 U+1F1E6",
                "name": "Bosnia and Herzegovina",
                "title": "flag for Bosnia and Herzegovina"
            },
            {
                "code": "BB",
                "emoji": "🇧🇧",
                "unicode": "U+1F1E7 U+1F1E7",
                "name": "Barbados",
                "title": "flag for Barbados"
            },
            {
                "code": "BD",
                "emoji": "🇧🇩",
                "unicode": "U+1F1E7 U+1F1E9",
                "name": "Bangladesh",
                "title": "flag for Bangladesh"
            },
            {
                "code": "BE",
                "emoji": "🇧🇪",
                "unicode": "U+1F1E7 U+1F1EA",
                "name": "Belgium",
                "title": "flag for Belgium"
            },
            {
                "code": "BF",
                "emoji": "🇧🇫",
                "unicode": "U+1F1E7 U+1F1EB",
                "name": "Burkina Faso",
                "title": "flag for Burkina Faso"
            },
            {
                "code": "BG",
                "emoji": "🇧🇬",
                "unicode": "U+1F1E7 U+1F1EC",
                "name": "Bulgaria",
                "title": "flag for Bulgaria"
            },
            {
                "code": "BH",
                "emoji": "🇧🇭",
                "unicode": "U+1F1E7 U+1F1ED",
                "name": "Bahrain",
                "title": "flag for Bahrain"
            },
            {
                "code": "BI",
                "emoji": "🇧🇮",
                "unicode": "U+1F1E7 U+1F1EE",
                "name": "Burundi",
                "title": "flag for Burundi"
            },
            {
                "code": "BJ",
                "emoji": "🇧🇯",
                "unicode": "U+1F1E7 U+1F1EF",
                "name": "Benin",
                "title": "flag for Benin"
            },
            {
                "code": "BL",
                "emoji": "🇧🇱",
                "unicode": "U+1F1E7 U+1F1F1",
                "name": "Saint Barthélemy",
                "title": "flag for Saint Barthélemy"
            },
            {
                "code": "BM",
                "emoji": "🇧🇲",
                "unicode": "U+1F1E7 U+1F1F2",
                "name": "Bermuda",
                "title": "flag for Bermuda"
            },
            {
                "code": "BN",
                "emoji": "🇧🇳",
                "unicode": "U+1F1E7 U+1F1F3",
                "name": "Brunei Darussalam",
                "title": "flag for Brunei Darussalam"
            },
            {
                "code": "BO",
                "emoji": "🇧🇴",
                "unicode": "U+1F1E7 U+1F1F4",
                "name": "Bolivia",
                "title": "flag for Bolivia"
            },
            {
                "code": "BQ",
                "emoji": "🇧🇶",
                "unicode": "U+1F1E7 U+1F1F6",
                "name": "Bonaire, Sint Eustatius and Saba",
                "title": "flag for Bonaire, Sint Eustatius and Saba"
            },
            {
                "code": "BR",
                "emoji": "🇧🇷",
                "unicode": "U+1F1E7 U+1F1F7",
                "name": "Brazil",
                "title": "flag for Brazil"
            },
            {
                "code": "BS",
                "emoji": "🇧🇸",
                "unicode": "U+1F1E7 U+1F1F8",
                "name": "Bahamas",
                "title": "flag for Bahamas"
            },
            {
                "code": "BT",
                "emoji": "🇧🇹",
                "unicode": "U+1F1E7 U+1F1F9",
                "name": "Bhutan",
                "title": "flag for Bhutan"
            },
            {
                "code": "BV",
                "emoji": "🇧🇻",
                "unicode": "U+1F1E7 U+1F1FB",
                "name": "Bouvet Island",
                "title": "flag for Bouvet Island"
            },
            {
                "code": "BW",
                "emoji": "🇧🇼",
                "unicode": "U+1F1E7 U+1F1FC",
                "name": "Botswana",
                "title": "flag for Botswana"
            },
            {
                "code": "BY",
                "emoji": "🇧🇾",
                "unicode": "U+1F1E7 U+1F1FE",
                "name": "Belarus",
                "title": "flag for Belarus"
            },
            {
                "code": "BZ",
                "emoji": "🇧🇿",
                "unicode": "U+1F1E7 U+1F1FF",
                "name": "Belize",
                "title": "flag for Belize"
            },
            {
                "code": "CA",
                "emoji": "🇨🇦",
                "unicode": "U+1F1E8 U+1F1E6",
                "name": "Canada",
                "title": "flag for Canada"
            },
            {
                "code": "CC",
                "emoji": "🇨🇨",
                "unicode": "U+1F1E8 U+1F1E8",
                "name": "Cocos (Keeling) Islands",
                "title": "flag for Cocos (Keeling) Islands"
            },
            {
                "code": "CD",
                "emoji": "🇨🇩",
                "unicode": "U+1F1E8 U+1F1E9",
                "name": "Congo",
                "title": "flag for Congo"
            },
            {
                "code": "CF",
                "emoji": "🇨🇫",
                "unicode": "U+1F1E8 U+1F1EB",
                "name": "Central African Republic",
                "title": "flag for Central African Republic"
            },
            {
                "code": "CG",
                "emoji": "🇨🇬",
                "unicode": "U+1F1E8 U+1F1EC",
                "name": "Congo",
                "title": "flag for Congo"
            },
            {
                "code": "CH",
                "emoji": "🇨🇭",
                "unicode": "U+1F1E8 U+1F1ED",
                "name": "Switzerland",
                "title": "flag for Switzerland"
            },
            {
                "code": "CI",
                "emoji": "🇨🇮",
                "unicode": "U+1F1E8 U+1F1EE",
                "name": "Côte D'Ivoire",
                "title": "flag for Côte D'Ivoire"
            },
            {
                "code": "CK",
                "emoji": "🇨🇰",
                "unicode": "U+1F1E8 U+1F1F0",
                "name": "Cook Islands",
                "title": "flag for Cook Islands"
            },
            {
                "code": "CL",
                "emoji": "🇨🇱",
                "unicode": "U+1F1E8 U+1F1F1",
                "name": "Chile",
                "title": "flag for Chile"
            },
            {
                "code": "CM",
                "emoji": "🇨🇲",
                "unicode": "U+1F1E8 U+1F1F2",
                "name": "Cameroon",
                "title": "flag for Cameroon"
            },
            {
                "code": "CN",
                "emoji": "🇨🇳",
                "unicode": "U+1F1E8 U+1F1F3",
                "name": "China",
                "title": "flag for China"
            },
            {
                "code": "CO",
                "emoji": "🇨🇴",
                "unicode": "U+1F1E8 U+1F1F4",
                "name": "Colombia",
                "title": "flag for Colombia"
            },
            {
                "code": "CR",
                "emoji": "🇨🇷",
                "unicode": "U+1F1E8 U+1F1F7",
                "name": "Costa Rica",
                "title": "flag for Costa Rica"
            },
            {
                "code": "CU",
                "emoji": "🇨🇺",
                "unicode": "U+1F1E8 U+1F1FA",
                "name": "Cuba",
                "title": "flag for Cuba"
            },
            {
                "code": "CV",
                "emoji": "🇨🇻",
                "unicode": "U+1F1E8 U+1F1FB",
                "name": "Cape Verde",
                "title": "flag for Cape Verde"
            },
            {
                "code": "CW",
                "emoji": "🇨🇼",
                "unicode": "U+1F1E8 U+1F1FC",
                "name": "Curaçao",
                "title": "flag for Curaçao"
            },
            {
                "code": "CX",
                "emoji": "🇨🇽",
                "unicode": "U+1F1E8 U+1F1FD",
                "name": "Christmas Island",
                "title": "flag for Christmas Island"
            },
            {
                "code": "CY",
                "emoji": "🇨🇾",
                "unicode": "U+1F1E8 U+1F1FE",
                "name": "Cyprus",
                "title": "flag for Cyprus"
            },
            {
                "code": "CZ",
                "emoji": "🇨🇿",
                "unicode": "U+1F1E8 U+1F1FF",
                "name": "Czech Republic",
                "title": "flag for Czech Republic"
            },
            {
                "code": "DE",
                "emoji": "🇩🇪",
                "unicode": "U+1F1E9 U+1F1EA",
                "name": "Germany",
                "title": "flag for Germany"
            },
            {
                "code": "DJ",
                "emoji": "🇩🇯",
                "unicode": "U+1F1E9 U+1F1EF",
                "name": "Djibouti",
                "title": "flag for Djibouti"
            },
            {
                "code": "DK",
                "emoji": "🇩🇰",
                "unicode": "U+1F1E9 U+1F1F0",
                "name": "Denmark",
                "title": "flag for Denmark"
            },
            {
                "code": "DM",
                "emoji": "🇩🇲",
                "unicode": "U+1F1E9 U+1F1F2",
                "name": "Dominica",
                "title": "flag for Dominica"
            },
            {
                "code": "DO",
                "emoji": "🇩🇴",
                "unicode": "U+1F1E9 U+1F1F4",
                "name": "Dominican Republic",
                "title": "flag for Dominican Republic"
            },
            {
                "code": "DZ",
                "emoji": "🇩🇿",
                "unicode": "U+1F1E9 U+1F1FF",
                "name": "Algeria",
                "title": "flag for Algeria"
            },
            {
                "code": "EC",
                "emoji": "🇪🇨",
                "unicode": "U+1F1EA U+1F1E8",
                "name": "Ecuador",
                "title": "flag for Ecuador"
            },
            {
                "code": "EE",
                "emoji": "🇪🇪",
                "unicode": "U+1F1EA U+1F1EA",
                "name": "Estonia",
                "title": "flag for Estonia"
            },
            {
                "code": "EG",
                "emoji": "🇪🇬",
                "unicode": "U+1F1EA U+1F1EC",
                "name": "Egypt",
                "title": "flag for Egypt"
            },
            {
                "code": "EH",
                "emoji": "🇪🇭",
                "unicode": "U+1F1EA U+1F1ED",
                "name": "Western Sahara",
                "title": "flag for Western Sahara"
            },
            {
                "code": "ER",
                "emoji": "🇪🇷",
                "unicode": "U+1F1EA U+1F1F7",
                "name": "Eritrea",
                "title": "flag for Eritrea"
            },
            {
                "code": "ES",
                "emoji": "🇪🇸",
                "unicode": "U+1F1EA U+1F1F8",
                "name": "Spain",
                "title": "flag for Spain"
            },
            {
                "code": "ET",
                "emoji": "🇪🇹",
                "unicode": "U+1F1EA U+1F1F9",
                "name": "Ethiopia",
                "title": "flag for Ethiopia"
            },
            {
                "code": "FI",
                "emoji": "🇫🇮",
                "unicode": "U+1F1EB U+1F1EE",
                "name": "Finland",
                "title": "flag for Finland"
            },
            {
                "code": "FJ",
                "emoji": "🇫🇯",
                "unicode": "U+1F1EB U+1F1EF",
                "name": "Fiji",
                "title": "flag for Fiji"
            },
            {
                "code": "FK",
                "emoji": "🇫🇰",
                "unicode": "U+1F1EB U+1F1F0",
                "name": "Falkland Islands (Malvinas)",
                "title": "flag for Falkland Islands (Malvinas)"
            },
            {
                "code": "FM",
                "emoji": "🇫🇲",
                "unicode": "U+1F1EB U+1F1F2",
                "name": "Micronesia",
                "title": "flag for Micronesia"
            },
            {
                "code": "FO",
                "emoji": "🇫🇴",
                "unicode": "U+1F1EB U+1F1F4",
                "name": "Faroe Islands",
                "title": "flag for Faroe Islands"
            },
            {
                "code": "FR",
                "emoji": "🇫🇷",
                "unicode": "U+1F1EB U+1F1F7",
                "name": "France",
                "title": "flag for France"
            },
            {
                "code": "GA",
                "emoji": "🇬🇦",
                "unicode": "U+1F1EC U+1F1E6",
                "name": "Gabon",
                "title": "flag for Gabon"
            },
            {
                "code": "GB",
                "emoji": "🇬🇧",
                "unicode": "U+1F1EC U+1F1E7",
                "name": "United Kingdom",
                "title": "flag for United Kingdom"
            },
            {
                "code": "GD",
                "emoji": "🇬🇩",
                "unicode": "U+1F1EC U+1F1E9",
                "name": "Grenada",
                "title": "flag for Grenada"
            },
            {
                "code": "GE",
                "emoji": "🇬🇪",
                "unicode": "U+1F1EC U+1F1EA",
                "name": "Georgia",
                "title": "flag for Georgia"
            },
            {
                "code": "GF",
                "emoji": "🇬🇫",
                "unicode": "U+1F1EC U+1F1EB",
                "name": "French Guiana",
                "title": "flag for French Guiana"
            },
            {
                "code": "GG",
                "emoji": "🇬🇬",
                "unicode": "U+1F1EC U+1F1EC",
                "name": "Guernsey",
                "title": "flag for Guernsey"
            },
            {
                "code": "GH",
                "emoji": "🇬🇭",
                "unicode": "U+1F1EC U+1F1ED",
                "name": "Ghana",
                "title": "flag for Ghana"
            },
            {
                "code": "GI",
                "emoji": "🇬🇮",
                "unicode": "U+1F1EC U+1F1EE",
                "name": "Gibraltar",
                "title": "flag for Gibraltar"
            },
            {
                "code": "GL",
                "emoji": "🇬🇱",
                "unicode": "U+1F1EC U+1F1F1",
                "name": "Greenland",
                "title": "flag for Greenland"
            },
            {
                "code": "GM",
                "emoji": "🇬🇲",
                "unicode": "U+1F1EC U+1F1F2",
                "name": "Gambia",
                "title": "flag for Gambia"
            },
            {
                "code": "GN",
                "emoji": "🇬🇳",
                "unicode": "U+1F1EC U+1F1F3",
                "name": "Guinea",
                "title": "flag for Guinea"
            },
            {
                "code": "GP",
                "emoji": "🇬🇵",
                "unicode": "U+1F1EC U+1F1F5",
                "name": "Guadeloupe",
                "title": "flag for Guadeloupe"
            },
            {
                "code": "GQ",
                "emoji": "🇬🇶",
                "unicode": "U+1F1EC U+1F1F6",
                "name": "Equatorial Guinea",
                "title": "flag for Equatorial Guinea"
            },
            {
                "code": "GR",
                "emoji": "🇬🇷",
                "unicode": "U+1F1EC U+1F1F7",
                "name": "Greece",
                "title": "flag for Greece"
            },
            {
                "code": "GS",
                "emoji": "🇬🇸",
                "unicode": "U+1F1EC U+1F1F8",
                "name": "South Georgia",
                "title": "flag for South Georgia"
            },
            {
                "code": "GT",
                "emoji": "🇬🇹",
                "unicode": "U+1F1EC U+1F1F9",
                "name": "Guatemala",
                "title": "flag for Guatemala"
            },
            {
                "code": "GU",
                "emoji": "🇬🇺",
                "unicode": "U+1F1EC U+1F1FA",
                "name": "Guam",
                "title": "flag for Guam"
            },
            {
                "code": "GW",
                "emoji": "🇬🇼",
                "unicode": "U+1F1EC U+1F1FC",
                "name": "Guinea-Bissau",
                "title": "flag for Guinea-Bissau"
            },
            {
                "code": "GY",
                "emoji": "🇬🇾",
                "unicode": "U+1F1EC U+1F1FE",
                "name": "Guyana",
                "title": "flag for Guyana"
            },
            {
                "code": "HK",
                "emoji": "🇭🇰",
                "unicode": "U+1F1ED U+1F1F0",
                "name": "Hong Kong",
                "title": "flag for Hong Kong"
            },
            {
                "code": "HM",
                "emoji": "🇭🇲",
                "unicode": "U+1F1ED U+1F1F2",
                "name": "Heard Island and Mcdonald Islands",
                "title": "flag for Heard Island and Mcdonald Islands"
            },
            {
                "code": "HN",
                "emoji": "🇭🇳",
                "unicode": "U+1F1ED U+1F1F3",
                "name": "Honduras",
                "title": "flag for Honduras"
            },
            {
                "code": "HR",
                "emoji": "🇭🇷",
                "unicode": "U+1F1ED U+1F1F7",
                "name": "Croatia",
                "title": "flag for Croatia"
            },
            {
                "code": "HT",
                "emoji": "🇭🇹",
                "unicode": "U+1F1ED U+1F1F9",
                "name": "Haiti",
                "title": "flag for Haiti"
            },
            {
                "code": "HU",
                "emoji": "🇭🇺",
                "unicode": "U+1F1ED U+1F1FA",
                "name": "Hungary",
                "title": "flag for Hungary"
            },
            {
                "code": "ID",
                "emoji": "🇮🇩",
                "unicode": "U+1F1EE U+1F1E9",
                "name": "Indonesia",
                "title": "flag for Indonesia"
            },
            {
                "code": "IE",
                "emoji": "🇮🇪",
                "unicode": "U+1F1EE U+1F1EA",
                "name": "Ireland",
                "title": "flag for Ireland"
            },
            {
                "code": "IM",
                "emoji": "🇮🇲",
                "unicode": "U+1F1EE U+1F1F2",
                "name": "Isle of Man",
                "title": "flag for Isle of Man"
            },
            {
                "code": "IN",
                "emoji": "🇮🇳",
                "unicode": "U+1F1EE U+1F1F3",
                "name": "India",
                "title": "flag for India"
            },
            {
                "code": "IO",
                "emoji": "🇮🇴",
                "unicode": "U+1F1EE U+1F1F4",
                "name": "British Indian Ocean Territory",
                "title": "flag for British Indian Ocean Territory"
            },
            {
                "code": "IQ",
                "emoji": "🇮🇶",
                "unicode": "U+1F1EE U+1F1F6",
                "name": "Iraq",
                "title": "flag for Iraq"
            },
            {
                "code": "IR",
                "emoji": "🇮🇷",
                "unicode": "U+1F1EE U+1F1F7",
                "name": "Iran",
                "title": "flag for Iran"
            },
            {
                "code": "IS",
                "emoji": "🇮🇸",
                "unicode": "U+1F1EE U+1F1F8",
                "name": "Iceland",
                "title": "flag for Iceland"
            },
            {
                "code": "IT",
                "emoji": "🇮🇹",
                "unicode": "U+1F1EE U+1F1F9",
                "name": "Italy",
                "title": "flag for Italy"
            },
            {
                "code": "JE",
                "emoji": "🇯🇪",
                "unicode": "U+1F1EF U+1F1EA",
                "name": "Jersey",
                "title": "flag for Jersey"
            },
            {
                "code": "JM",
                "emoji": "🇯🇲",
                "unicode": "U+1F1EF U+1F1F2",
                "name": "Jamaica",
                "title": "flag for Jamaica"
            },
            {
                "code": "JO",
                "emoji": "🇯🇴",
                "unicode": "U+1F1EF U+1F1F4",
                "name": "Jordan",
                "title": "flag for Jordan"
            },
            {
                "code": "JP",
                "emoji": "🇯🇵",
                "unicode": "U+1F1EF U+1F1F5",
                "name": "Japan",
                "title": "flag for Japan"
            },
            {
                "code": "KE",
                "emoji": "🇰🇪",
                "unicode": "U+1F1F0 U+1F1EA",
                "name": "Kenya",
                "title": "flag for Kenya"
            },
            {
                "code": "KG",
                "emoji": "🇰🇬",
                "unicode": "U+1F1F0 U+1F1EC",
                "name": "Kyrgyzstan",
                "title": "flag for Kyrgyzstan"
            },
            {
                "code": "KH",
                "emoji": "🇰🇭",
                "unicode": "U+1F1F0 U+1F1ED",
                "name": "Cambodia",
                "title": "flag for Cambodia"
            },
            {
                "code": "KI",
                "emoji": "🇰🇮",
                "unicode": "U+1F1F0 U+1F1EE",
                "name": "Kiribati",
                "title": "flag for Kiribati"
            },
            {
                "code": "KM",
                "emoji": "🇰🇲",
                "unicode": "U+1F1F0 U+1F1F2",
                "name": "Comoros",
                "title": "flag for Comoros"
            },
            {
                "code": "KN",
                "emoji": "🇰🇳",
                "unicode": "U+1F1F0 U+1F1F3",
                "name": "Saint Kitts and Nevis",
                "title": "flag for Saint Kitts and Nevis"
            },
            {
                "code": "KP",
                "emoji": "🇰🇵",
                "unicode": "U+1F1F0 U+1F1F5",
                "name": "North Korea",
                "title": "flag for North Korea"
            },
            {
                "code": "KR",
                "emoji": "🇰🇷",
                "unicode": "U+1F1F0 U+1F1F7",
                "name": "South Korea",
                "title": "flag for South Korea"
            },
            {
                "code": "KW",
                "emoji": "🇰🇼",
                "unicode": "U+1F1F0 U+1F1FC",
                "name": "Kuwait",
                "title": "flag for Kuwait"
            },
            {
                "code": "KY",
                "emoji": "🇰🇾",
                "unicode": "U+1F1F0 U+1F1FE",
                "name": "Cayman Islands",
                "title": "flag for Cayman Islands"
            },
            {
                "code": "KZ",
                "emoji": "🇰🇿",
                "unicode": "U+1F1F0 U+1F1FF",
                "name": "Kazakhstan",
                "title": "flag for Kazakhstan"
            },
            {
                "code": "LA",
                "emoji": "🇱🇦",
                "unicode": "U+1F1F1 U+1F1E6",
                "name": "Lao People's Democratic Republic",
                "title": "flag for Lao People's Democratic Republic"
            },
            {
                "code": "LB",
                "emoji": "🇱🇧",
                "unicode": "U+1F1F1 U+1F1E7",
                "name": "Lebanon",
                "title": "flag for Lebanon"
            },
            {
                "code": "LC",
                "emoji": "🇱🇨",
                "unicode": "U+1F1F1 U+1F1E8",
                "name": "Saint Lucia",
                "title": "flag for Saint Lucia"
            },
            {
                "code": "LI",
                "emoji": "🇱🇮",
                "unicode": "U+1F1F1 U+1F1EE",
                "name": "Liechtenstein",
                "title": "flag for Liechtenstein"
            },
            {
                "code": "LK",
                "emoji": "🇱🇰",
                "unicode": "U+1F1F1 U+1F1F0",
                "name": "Sri Lanka",
                "title": "flag for Sri Lanka"
            },
            {
                "code": "LR",
                "emoji": "🇱🇷",
                "unicode": "U+1F1F1 U+1F1F7",
                "name": "Liberia",
                "title": "flag for Liberia"
            },
            {
                "code": "LS",
                "emoji": "🇱🇸",
                "unicode": "U+1F1F1 U+1F1F8",
                "name": "Lesotho",
                "title": "flag for Lesotho"
            },
            {
                "code": "LT",
                "emoji": "🇱🇹",
                "unicode": "U+1F1F1 U+1F1F9",
                "name": "Lithuania",
                "title": "flag for Lithuania"
            },
            {
                "code": "LU",
                "emoji": "🇱🇺",
                "unicode": "U+1F1F1 U+1F1FA",
                "name": "Luxembourg",
                "title": "flag for Luxembourg"
            },
            {
                "code": "LV",
                "emoji": "🇱🇻",
                "unicode": "U+1F1F1 U+1F1FB",
                "name": "Latvia",
                "title": "flag for Latvia"
            },
            {
                "code": "LY",
                "emoji": "🇱🇾",
                "unicode": "U+1F1F1 U+1F1FE",
                "name": "Libya",
                "title": "flag for Libya"
            },
            {
                "code": "MA",
                "emoji": "🇲🇦",
                "unicode": "U+1F1F2 U+1F1E6",
                "name": "Morocco",
                "title": "flag for Morocco"
            },
            {
                "code": "MC",
                "emoji": "🇲🇨",
                "unicode": "U+1F1F2 U+1F1E8",
                "name": "Monaco",
                "title": "flag for Monaco"
            },
            {
                "code": "MD",
                "emoji": "🇲🇩",
                "unicode": "U+1F1F2 U+1F1E9",
                "name": "Moldova",
                "title": "flag for Moldova"
            },
            {
                "code": "ME",
                "emoji": "🇲🇪",
                "unicode": "U+1F1F2 U+1F1EA",
                "name": "Montenegro",
                "title": "flag for Montenegro"
            },
            {
                "code": "MF",
                "emoji": "🇲🇫",
                "unicode": "U+1F1F2 U+1F1EB",
                "name": "Saint Martin (French Part)",
                "title": "flag for Saint Martin (French Part)"
            },
            {
                "code": "MG",
                "emoji": "🇲🇬",
                "unicode": "U+1F1F2 U+1F1EC",
                "name": "Madagascar",
                "title": "flag for Madagascar"
            },
            {
                "code": "MH",
                "emoji": "🇲🇭",
                "unicode": "U+1F1F2 U+1F1ED",
                "name": "Marshall Islands",
                "title": "flag for Marshall Islands"
            },
            {
                "code": "MK",
                "emoji": "🇲🇰",
                "unicode": "U+1F1F2 U+1F1F0",
                "name": "Macedonia",
                "title": "flag for Macedonia"
            },
            {
                "code": "ML",
                "emoji": "🇲🇱",
                "unicode": "U+1F1F2 U+1F1F1",
                "name": "Mali",
                "title": "flag for Mali"
            },
            {
                "code": "MM",
                "emoji": "🇲🇲",
                "unicode": "U+1F1F2 U+1F1F2",
                "name": "Myanmar",
                "title": "flag for Myanmar"
            },
            {
                "code": "MN",
                "emoji": "🇲🇳",
                "unicode": "U+1F1F2 U+1F1F3",
                "name": "Mongolia",
                "title": "flag for Mongolia"
            },
            {
                "code": "MO",
                "emoji": "🇲🇴",
                "unicode": "U+1F1F2 U+1F1F4",
                "name": "Macao",
                "title": "flag for Macao"
            },
            {
                "code": "MP",
                "emoji": "🇲🇵",
                "unicode": "U+1F1F2 U+1F1F5",
                "name": "Northern Mariana Islands",
                "title": "flag for Northern Mariana Islands"
            },
            {
                "code": "MQ",
                "emoji": "🇲🇶",
                "unicode": "U+1F1F2 U+1F1F6",
                "name": "Martinique",
                "title": "flag for Martinique"
            },
            {
                "code": "MR",
                "emoji": "🇲🇷",
                "unicode": "U+1F1F2 U+1F1F7",
                "name": "Mauritania",
                "title": "flag for Mauritania"
            },
            {
                "code": "MS",
                "emoji": "🇲🇸",
                "unicode": "U+1F1F2 U+1F1F8",
                "name": "Montserrat",
                "title": "flag for Montserrat"
            },
            {
                "code": "MT",
                "emoji": "🇲🇹",
                "unicode": "U+1F1F2 U+1F1F9",
                "name": "Malta",
                "title": "flag for Malta"
            },
            {
                "code": "MU",
                "emoji": "🇲🇺",
                "unicode": "U+1F1F2 U+1F1FA",
                "name": "Mauritius",
                "title": "flag for Mauritius"
            },
            {
                "code": "MV",
                "emoji": "🇲🇻",
                "unicode": "U+1F1F2 U+1F1FB",
                "name": "Maldives",
                "title": "flag for Maldives"
            },
            {
                "code": "MW",
                "emoji": "🇲🇼",
                "unicode": "U+1F1F2 U+1F1FC",
                "name": "Malawi",
                "title": "flag for Malawi"
            },
            {
                "code": "MX",
                "emoji": "🇲🇽",
                "unicode": "U+1F1F2 U+1F1FD",
                "name": "Mexico",
                "title": "flag for Mexico"
            },
            {
                "code": "MY",
                "emoji": "🇲🇾",
                "unicode": "U+1F1F2 U+1F1FE",
                "name": "Malaysia",
                "title": "flag for Malaysia"
            },
            {
                "code": "MZ",
                "emoji": "🇲🇿",
                "unicode": "U+1F1F2 U+1F1FF",
                "name": "Mozambique",
                "title": "flag for Mozambique"
            },
            {
                "code": "NA",
                "emoji": "🇳🇦",
                "unicode": "U+1F1F3 U+1F1E6",
                "name": "Namibia",
                "title": "flag for Namibia"
            },
            {
                "code": "NC",
                "emoji": "🇳🇨",
                "unicode": "U+1F1F3 U+1F1E8",
                "name": "New Caledonia",
                "title": "flag for New Caledonia"
            },
            {
                "code": "NE",
                "emoji": "🇳🇪",
                "unicode": "U+1F1F3 U+1F1EA",
                "name": "Niger",
                "title": "flag for Niger"
            },
            {
                "code": "NF",
                "emoji": "🇳🇫",
                "unicode": "U+1F1F3 U+1F1EB",
                "name": "Norfolk Island",
                "title": "flag for Norfolk Island"
            },
            {
                "code": "NG",
                "emoji": "🇳🇬",
                "unicode": "U+1F1F3 U+1F1EC",
                "name": "Nigeria",
                "title": "flag for Nigeria"
            },
            {
                "code": "NI",
                "emoji": "🇳🇮",
                "unicode": "U+1F1F3 U+1F1EE",
                "name": "Nicaragua",
                "title": "flag for Nicaragua"
            },
            {
                "code": "NL",
                "emoji": "🇳🇱",
                "unicode": "U+1F1F3 U+1F1F1",
                "name": "Netherlands",
                "title": "flag for Netherlands"
            },
            {
                "code": "NO",
                "emoji": "🇳🇴",
                "unicode": "U+1F1F3 U+1F1F4",
                "name": "Norway",
                "title": "flag for Norway"
            },
            {
                "code": "NP",
                "emoji": "🇳🇵",
                "unicode": "U+1F1F3 U+1F1F5",
                "name": "Nepal",
                "title": "flag for Nepal"
            },
            {
                "code": "NR",
                "emoji": "🇳🇷",
                "unicode": "U+1F1F3 U+1F1F7",
                "name": "Nauru",
                "title": "flag for Nauru"
            },
            {
                "code": "NU",
                "emoji": "🇳🇺",
                "unicode": "U+1F1F3 U+1F1FA",
                "name": "Niue",
                "title": "flag for Niue"
            },
            {
                "code": "NZ",
                "emoji": "🇳🇿",
                "unicode": "U+1F1F3 U+1F1FF",
                "name": "New Zealand",
                "title": "flag for New Zealand"
            },
            {
                "code": "OM",
                "emoji": "🇴🇲",
                "unicode": "U+1F1F4 U+1F1F2",
                "name": "Oman",
                "title": "flag for Oman"
            },
            {
                "code": "PA",
                "emoji": "🇵🇦",
                "unicode": "U+1F1F5 U+1F1E6",
                "name": "Panama",
                "title": "flag for Panama"
            },
            {
                "code": "PE",
                "emoji": "🇵🇪",
                "unicode": "U+1F1F5 U+1F1EA",
                "name": "Peru",
                "title": "flag for Peru"
            },
            {
                "code": "PF",
                "emoji": "🇵🇫",
                "unicode": "U+1F1F5 U+1F1EB",
                "name": "French Polynesia",
                "title": "flag for French Polynesia"
            },
            {
                "code": "PG",
                "emoji": "🇵🇬",
                "unicode": "U+1F1F5 U+1F1EC",
                "name": "Papua New Guinea",
                "title": "flag for Papua New Guinea"
            },
            {
                "code": "PH",
                "emoji": "🇵🇭",
                "unicode": "U+1F1F5 U+1F1ED",
                "name": "Philippines",
                "title": "flag for Philippines"
            },
            {
                "code": "PK",
                "emoji": "🇵🇰",
                "unicode": "U+1F1F5 U+1F1F0",
                "name": "Pakistan",
                "title": "flag for Pakistan"
            },
            {
                "code": "PL",
                "emoji": "🇵🇱",
                "unicode": "U+1F1F5 U+1F1F1",
                "name": "Poland",
                "title": "flag for Poland"
            },
            {
                "code": "PM",
                "emoji": "🇵🇲",
                "unicode": "U+1F1F5 U+1F1F2",
                "name": "Saint Pierre and Miquelon",
                "title": "flag for Saint Pierre and Miquelon"
            },
            {
                "code": "PN",
                "emoji": "🇵🇳",
                "unicode": "U+1F1F5 U+1F1F3",
                "name": "Pitcairn",
                "title": "flag for Pitcairn"
            },
            {
                "code": "PR",
                "emoji": "🇵🇷",
                "unicode": "U+1F1F5 U+1F1F7",
                "name": "Puerto Rico",
                "title": "flag for Puerto Rico"
            },
            {
                "code": "PS",
                "emoji": "🇵🇸",
                "unicode": "U+1F1F5 U+1F1F8",
                "name": "Palestine",
                "title": "flag for Palestine"
            },
            {
                "code": "PT",
                "emoji": "🇵🇹",
                "unicode": "U+1F1F5 U+1F1F9",
                "name": "Portugal",
                "title": "flag for Portugal"
            },
            {
                "code": "PW",
                "emoji": "🇵🇼",
                "unicode": "U+1F1F5 U+1F1FC",
                "name": "Palau",
                "title": "flag for Palau"
            },
            {
                "code": "PY",
                "emoji": "🇵🇾",
                "unicode": "U+1F1F5 U+1F1FE",
                "name": "Paraguay",
                "title": "flag for Paraguay"
            },
            {
                "code": "QA",
                "emoji": "🇶🇦",
                "unicode": "U+1F1F6 U+1F1E6",
                "name": "Qatar",
                "title": "flag for Qatar"
            },
            {
                "code": "RE",
                "emoji": "🇷🇪",
                "unicode": "U+1F1F7 U+1F1EA",
                "name": "Réunion",
                "title": "flag for Réunion"
            },
            {
                "code": "RO",
                "emoji": "🇷🇴",
                "unicode": "U+1F1F7 U+1F1F4",
                "name": "Romania",
                "title": "flag for Romania"
            },
            {
                "code": "RS",
                "emoji": "🇷🇸",
                "unicode": "U+1F1F7 U+1F1F8",
                "name": "Serbia",
                "title": "flag for Serbia"
            },
            {
                "code": "RU",
                "emoji": "🇷🇺",
                "unicode": "U+1F1F7 U+1F1FA",
                "name": "Russia",
                "title": "flag for Russia"
            },
            {
                "code": "RW",
                "emoji": "🇷🇼",
                "unicode": "U+1F1F7 U+1F1FC",
                "name": "Rwanda",
                "title": "flag for Rwanda"
            },
            {
                "code": "SA",
                "emoji": "🇸🇦",
                "unicode": "U+1F1F8 U+1F1E6",
                "name": "Saudi Arabia",
                "title": "flag for Saudi Arabia"
            },
            {
                "code": "SB",
                "emoji": "🇸🇧",
                "unicode": "U+1F1F8 U+1F1E7",
                "name": "Solomon Islands",
                "title": "flag for Solomon Islands"
            },
            {
                "code": "SC",
                "emoji": "🇸🇨",
                "unicode": "U+1F1F8 U+1F1E8",
                "name": "Seychelles",
                "title": "flag for Seychelles"
            },
            {
                "code": "SD",
                "emoji": "🇸🇩",
                "unicode": "U+1F1F8 U+1F1E9",
                "name": "Sudan",
                "title": "flag for Sudan"
            },
            {
                "code": "SE",
                "emoji": "🇸🇪",
                "unicode": "U+1F1F8 U+1F1EA",
                "name": "Sweden",
                "title": "flag for Sweden"
            },
            {
                "code": "SG",
                "emoji": "🇸🇬",
                "unicode": "U+1F1F8 U+1F1EC",
                "name": "Singapore",
                "title": "flag for Singapore"
            },
            {
                "code": "SH",
                "emoji": "🇸🇭",
                "unicode": "U+1F1F8 U+1F1ED",
                "name": "Saint Helena, Ascension and Tristan Da Cunha",
                "title": "flag for Saint Helena, Ascension and Tristan Da Cunha"
            },
            {
                "code": "SI",
                "emoji": "🇸🇮",
                "unicode": "U+1F1F8 U+1F1EE",
                "name": "Slovenia",
                "title": "flag for Slovenia"
            },
            {
                "code": "SJ",
                "emoji": "🇸🇯",
                "unicode": "U+1F1F8 U+1F1EF",
                "name": "Svalbard and Jan Mayen",
                "title": "flag for Svalbard and Jan Mayen"
            },
            {
                "code": "SK",
                "emoji": "🇸🇰",
                "unicode": "U+1F1F8 U+1F1F0",
                "name": "Slovakia",
                "title": "flag for Slovakia"
            },
            {
                "code": "SL",
                "emoji": "🇸🇱",
                "unicode": "U+1F1F8 U+1F1F1",
                "name": "Sierra Leone",
                "title": "flag for Sierra Leone"
            },
            {
                "code": "SM",
                "emoji": "🇸🇲",
                "unicode": "U+1F1F8 U+1F1F2",
                "name": "San Marino",
                "title": "flag for San Marino"
            },
            {
                "code": "SN",
                "emoji": "🇸🇳",
                "unicode": "U+1F1F8 U+1F1F3",
                "name": "Senegal",
                "title": "flag for Senegal"
            },
            {
                "code": "SO",
                "emoji": "🇸🇴",
                "unicode": "U+1F1F8 U+1F1F4",
                "name": "Somalia",
                "title": "flag for Somalia"
            },
            {
                "code": "SR",
                "emoji": "🇸🇷",
                "unicode": "U+1F1F8 U+1F1F7",
                "name": "Suriname",
                "title": "flag for Suriname"
            },
            {
                "code": "SS",
                "emoji": "🇸🇸",
                "unicode": "U+1F1F8 U+1F1F8",
                "name": "South Sudan",
                "title": "flag for South Sudan"
            },
            {
                "code": "ST",
                "emoji": "🇸🇹",
                "unicode": "U+1F1F8 U+1F1F9",
                "name": "Sao Tome and Principe",
                "title": "flag for Sao Tome and Principe"
            },
            {
                "code": "SV",
                "emoji": "🇸🇻",
                "unicode": "U+1F1F8 U+1F1FB",
                "name": "El Salvador",
                "title": "flag for El Salvador"
            },
            {
                "code": "SX",
                "emoji": "🇸🇽",
                "unicode": "U+1F1F8 U+1F1FD",
                "name": "Sint Maarten (Dutch Part)",
                "title": "flag for Sint Maarten (Dutch Part)"
            },
            {
                "code": "SY",
                "emoji": "🇸🇾",
                "unicode": "U+1F1F8 U+1F1FE",
                "name": "Syrian Arab Republic",
                "title": "flag for Syrian Arab Republic"
            },
            {
                "code": "SZ",
                "emoji": "🇸🇿",
                "unicode": "U+1F1F8 U+1F1FF",
                "name": "Swaziland",
                "title": "flag for Swaziland"
            },
            {
                "code": "TC",
                "emoji": "🇹🇨",
                "unicode": "U+1F1F9 U+1F1E8",
                "name": "Turks and Caicos Islands",
                "title": "flag for Turks and Caicos Islands"
            },
            {
                "code": "TD",
                "emoji": "🇹🇩",
                "unicode": "U+1F1F9 U+1F1E9",
                "name": "Chad",
                "title": "flag for Chad"
            },
            {
                "code": "TF",
                "emoji": "🇹🇫",
                "unicode": "U+1F1F9 U+1F1EB",
                "name": "French Southern Territories",
                "title": "flag for French Southern Territories"
            },
            {
                "code": "TG",
                "emoji": "🇹🇬",
                "unicode": "U+1F1F9 U+1F1EC",
                "name": "Togo",
                "title": "flag for Togo"
            },
            {
                "code": "TH",
                "emoji": "🇹🇭",
                "unicode": "U+1F1F9 U+1F1ED",
                "name": "Thailand",
                "title": "flag for Thailand"
            },
            {
                "code": "TJ",
                "emoji": "🇹🇯",
                "unicode": "U+1F1F9 U+1F1EF",
                "name": "Tajikistan",
                "title": "flag for Tajikistan"
            },
            {
                "code": "TK",
                "emoji": "🇹🇰",
                "unicode": "U+1F1F9 U+1F1F0",
                "name": "Tokelau",
                "title": "flag for Tokelau"
            },
            {
                "code": "TL",
                "emoji": "🇹🇱",
                "unicode": "U+1F1F9 U+1F1F1",
                "name": "Timor-Leste",
                "title": "flag for Timor-Leste"
            },
            {
                "code": "TM",
                "emoji": "🇹🇲",
                "unicode": "U+1F1F9 U+1F1F2",
                "name": "Turkmenistan",
                "title": "flag for Turkmenistan"
            },
            {
                "code": "TN",
                "emoji": "🇹🇳",
                "unicode": "U+1F1F9 U+1F1F3",
                "name": "Tunisia",
                "title": "flag for Tunisia"
            },
            {
                "code": "TO",
                "emoji": "🇹🇴",
                "unicode": "U+1F1F9 U+1F1F4",
                "name": "Tonga",
                "title": "flag for Tonga"
            },
            {
                "code": "TR",
                "emoji": "🇹🇷",
                "unicode": "U+1F1F9 U+1F1F7",
                "name": "Turkey",
                "title": "flag for Turkey"
            },
            {
                "code": "TT",
                "emoji": "🇹🇹",
                "unicode": "U+1F1F9 U+1F1F9",
                "name": "Trinidad and Tobago",
                "title": "flag for Trinidad and Tobago"
            },
            {
                "code": "TV",
                "emoji": "🇹🇻",
                "unicode": "U+1F1F9 U+1F1FB",
                "name": "Tuvalu",
                "title": "flag for Tuvalu"
            },
            {
                "code": "TW",
                "emoji": "🇹🇼",
                "unicode": "U+1F1F9 U+1F1FC",
                "name": "Taiwan",
                "title": "flag for Taiwan"
            },
            {
                "code": "TZ",
                "emoji": "🇹🇿",
                "unicode": "U+1F1F9 U+1F1FF",
                "name": "Tanzania",
                "title": "flag for Tanzania"
            },
            {
                "code": "UA",
                "emoji": "🇺🇦",
                "unicode": "U+1F1FA U+1F1E6",
                "name": "Ukraine",
                "title": "flag for Ukraine"
            },
            {
                "code": "UG",
                "emoji": "🇺🇬",
                "unicode": "U+1F1FA U+1F1EC",
                "name": "Uganda",
                "title": "flag for Uganda"
            },
            {
                "code": "UM",
                "emoji": "🇺🇲",
                "unicode": "U+1F1FA U+1F1F2",
                "name": "United States Minor Outlying Islands",
                "title": "flag for United States Minor Outlying Islands"
            },
            {
                "code": "US",
                "emoji": "🇺🇸",
                "unicode": "U+1F1FA U+1F1F8",
                "name": "United States",
                "title": "flag for United States"
            },
            {
                "code": "UY",
                "emoji": "🇺🇾",
                "unicode": "U+1F1FA U+1F1FE",
                "name": "Uruguay",
                "title": "flag for Uruguay"
            },
            {
                "code": "UZ",
                "emoji": "🇺🇿",
                "unicode": "U+1F1FA U+1F1FF",
                "name": "Uzbekistan",
                "title": "flag for Uzbekistan"
            },
            {
                "code": "VA",
                "emoji": "🇻🇦",
                "unicode": "U+1F1FB U+1F1E6",
                "name": "Vatican City",
                "title": "flag for Vatican City"
            },
            {
                "code": "VC",
                "emoji": "🇻🇨",
                "unicode": "U+1F1FB U+1F1E8",
                "name": "Saint Vincent and The Grenadines",
                "title": "flag for Saint Vincent and The Grenadines"
            },
            {
                "code": "VE",
                "emoji": "🇻🇪",
                "unicode": "U+1F1FB U+1F1EA",
                "name": "Venezuela",
                "title": "flag for Venezuela"
            },
            {
                "code": "VG",
                "emoji": "🇻🇬",
                "unicode": "U+1F1FB U+1F1EC",
                "name": "Virgin Islands, British",
                "title": "flag for Virgin Islands, British"
            },
            {
                "code": "VI",
                "emoji": "🇻🇮",
                "unicode": "U+1F1FB U+1F1EE",
                "name": "Virgin Islands, U.S.",
                "title": "flag for Virgin Islands, U.S."
            },
            {
                "code": "VN",
                "emoji": "🇻🇳",
                "unicode": "U+1F1FB U+1F1F3",
                "name": "Viet Nam",
                "title": "flag for Viet Nam"
            },
            {
                "code": "VU",
                "emoji": "🇻🇺",
                "unicode": "U+1F1FB U+1F1FA",
                "name": "Vanuatu",
                "title": "flag for Vanuatu"
            },
            {
                "code": "WF",
                "emoji": "🇼🇫",
                "unicode": "U+1F1FC U+1F1EB",
                "name": "Wallis and Futuna",
                "title": "flag for Wallis and Futuna"
            },
            {
                "code": "WS",
                "emoji": "🇼🇸",
                "unicode": "U+1F1FC U+1F1F8",
                "name": "Samoa",
                "title": "flag for Samoa"
            },
            {
                "code": "YE",
                "emoji": "🇾🇪",
                "unicode": "U+1F1FE U+1F1EA",
                "name": "Yemen",
                "title": "flag for Yemen"
            },
            {
                "code": "YT",
                "emoji": "🇾🇹",
                "unicode": "U+1F1FE U+1F1F9",
                "name": "Mayotte",
                "title": "flag for Mayotte"
            },
            {
                "code": "ZA",
                "emoji": "🇿🇦",
                "unicode": "U+1F1FF U+1F1E6",
                "name": "South Africa",
                "title": "flag for South Africa"
            },
            {
                "code": "ZM",
                "emoji": "🇿🇲",
                "unicode": "U+1F1FF U+1F1F2",
                "name": "Zambia",
                "title": "flag for Zambia"
            },
            {
                "code": "ZW",
                "emoji": "🇿🇼",
                "unicode": "U+1F1FF U+1F1FC",
                "name": "Zimbabwe",
                "title": "flag for Zimbabwe"
            }
        ]

    ];

    flagsCountryNameList = [
        {
            "name": "Ascension Island",
            "code": "AC",
            "emoji": "🇦🇨",
            "unicode": "U+1F1E6 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg"
        },
        {
            "name": "Andorra",
            "code": "AD",
            "emoji": "🇦🇩",
            "unicode": "U+1F1E6 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
        },
        {
            "name": "United Arab Emirates",
            "code": "AE",
            "emoji": "🇦🇪",
            "unicode": "U+1F1E6 U+1F1EA",
            "image": "../assets/svg-country-flags/curve-flags/AE.png"
        },
        {
            "name": "Afghanistan",
            "code": "AF",
            "emoji": "🇦🇫",
            "unicode": "U+1F1E6 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
        },
        {
            "name": "Antigua & Barbuda",
            "code": "AG",
            "emoji": "🇦🇬",
            "unicode": "U+1F1E6 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg"
        },
        {
            "name": "Anguilla",
            "code": "AI",
            "emoji": "🇦🇮",
            "unicode": "U+1F1E6 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
        },
        {
            "name": "Albania",
            "code": "AL",
            "emoji": "🇦🇱",
            "unicode": "U+1F1E6 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
        },
        {
            "name": "Armenia",
            "code": "AM",
            "emoji": "🇦🇲",
            "unicode": "U+1F1E6 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
        },
        {
            "name": "Angola",
            "code": "AO",
            "emoji": "🇦🇴",
            "unicode": "U+1F1E6 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
        },
        {
            "name": "Antarctica",
            "code": "AQ",
            "emoji": "🇦🇶",
            "unicode": "U+1F1E6 U+1F1F6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg"
        },
        {
            "name": "Argentina",
            "code": "AR",
            "emoji": "🇦🇷",
            "unicode": "U+1F1E6 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
        },
        {
            "name": "American Samoa",
            "code": "AS",
            "emoji": "🇦🇸",
            "unicode": "U+1F1E6 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
        },
        {
            "name": "Austria",
            "code": "AT",
            "emoji": "🇦🇹",
            "unicode": "U+1F1E6 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
        },
        {
            "name": "Australia",
            "code": "AU",
            "emoji": "🇦🇺",
            "unicode": "U+1F1E6 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
        },
        {
            "name": "Aruba",
            "code": "AW",
            "emoji": "🇦🇼",
            "unicode": "U+1F1E6 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
        },
        {
            "name": "Åland Islands",
            "code": "AX",
            "emoji": "🇦🇽",
            "unicode": "U+1F1E6 U+1F1FD",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg"
        },
        {
            "name": "Azerbaijan",
            "code": "AZ",
            "emoji": "🇦🇿",
            "unicode": "U+1F1E6 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
        },
        {
            "name": "Bosnia & Herzegovina",
            "code": "BA",
            "emoji": "🇧🇦",
            "unicode": "U+1F1E7 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
        },
        {
            "name": "Barbados",
            "code": "BB",
            "emoji": "🇧🇧",
            "unicode": "U+1F1E7 U+1F1E7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
        },
        {
            "name": "Bangladesh",
            "code": "BD",
            "emoji": "🇧🇩",
            "unicode": "U+1F1E7 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
        },
        {
            "name": "Belgium",
            "code": "BE",
            "emoji": "🇧🇪",
            "unicode": "U+1F1E7 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
        },
        {
            "name": "Burkina Faso",
            "code": "BF",
            "emoji": "🇧🇫",
            "unicode": "U+1F1E7 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
        },
        {
            "name": "Bulgaria",
            "code": "BG",
            "emoji": "🇧🇬",
            "unicode": "U+1F1E7 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
        },
        {
            "name": "Bahrain",
            "code": "BH",
            "emoji": "🇧🇭",
            "unicode": "U+1F1E7 U+1F1ED",
            "image": "../assets/svg-country-flags/curve-flags/BH.png"
        },
        {
            "name": "Burundi",
            "code": "BI",
            "emoji": "🇧🇮",
            "unicode": "U+1F1E7 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
        },
        {
            "name": "Benin",
            "code": "BJ",
            "emoji": "🇧🇯",
            "unicode": "U+1F1E7 U+1F1EF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
        },
        {
            "name": "St. Barthélemy",
            "code": "BL",
            "emoji": "🇧🇱",
            "unicode": "U+1F1E7 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg"
        },
        {
            "name": "Bermuda",
            "code": "BM",
            "emoji": "🇧🇲",
            "unicode": "U+1F1E7 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
        },
        {
            "name": "Brunei",
            "code": "BN",
            "emoji": "🇧🇳",
            "unicode": "U+1F1E7 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
        },
        {
            "name": "Bolivia",
            "code": "BO",
            "emoji": "🇧🇴",
            "unicode": "U+1F1E7 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
        },
        {
            "name": "Caribbean Netherlands",
            "code": "BQ",
            "emoji": "🇧🇶",
            "unicode": "U+1F1E7 U+1F1F6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg"
        },
        {
            "name": "Brazil",
            "code": "BR",
            "emoji": "🇧🇷",
            "unicode": "U+1F1E7 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
        },
        {
            "name": "Bahamas",
            "code": "BS",
            "emoji": "🇧🇸",
            "unicode": "U+1F1E7 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
        },
        {
            "name": "Bhutan",
            "code": "BT",
            "emoji": "🇧🇹",
            "unicode": "U+1F1E7 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
        },
        {
            "name": "Bouvet Island",
            "code": "BV",
            "emoji": "🇧🇻",
            "unicode": "U+1F1E7 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg"
        },
        {
            "name": "Botswana",
            "code": "BW",
            "emoji": "🇧🇼",
            "unicode": "U+1F1E7 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
        },
        {
            "name": "Belarus",
            "code": "BY",
            "emoji": "🇧🇾",
            "unicode": "U+1F1E7 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
        },
        {
            "name": "Belize",
            "code": "BZ",
            "emoji": "🇧🇿",
            "unicode": "U+1F1E7 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
        },
        {
            "name": "Canada",
            "code": "CA",
            "emoji": "🇨🇦",
            "unicode": "U+1F1E8 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "code": "CC",
            "emoji": "🇨🇨",
            "unicode": "U+1F1E8 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg"
        },
        {
            "name": "Congo - Kinshasa",
            "code": "CD",
            "emoji": "🇨🇩",
            "unicode": "U+1F1E8 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
        },
        {
            "name": "Central African Republic",
            "code": "CF",
            "emoji": "🇨🇫",
            "unicode": "U+1F1E8 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
        },
        {
            "name": "Congo - Brazzaville",
            "code": "CG",
            "emoji": "🇨🇬",
            "unicode": "U+1F1E8 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
        },
        {
            "name": "Switzerland",
            "code": "CH",
            "emoji": "🇨🇭",
            "unicode": "U+1F1E8 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
        },
        {
            "name": "Côte d’Ivoire",
            "code": "CI",
            "emoji": "🇨🇮",
            "unicode": "U+1F1E8 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
        },
        {
            "name": "Cook Islands",
            "code": "CK",
            "emoji": "🇨🇰",
            "unicode": "U+1F1E8 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
        },
        {
            "name": "Chile",
            "code": "CL",
            "emoji": "🇨🇱",
            "unicode": "U+1F1E8 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
        },
        {
            "name": "Cameroon",
            "code": "CM",
            "emoji": "🇨🇲",
            "unicode": "U+1F1E8 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
        },
        {
            "name": "China",
            "code": "CN",
            "emoji": "🇨🇳",
            "unicode": "U+1F1E8 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
        },
        {
            "name": "Colombia",
            "code": "CO",
            "emoji": "🇨🇴",
            "unicode": "U+1F1E8 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
        },
        {
            "name": "Clipperton Island",
            "code": "CP",
            "emoji": "🇨🇵",
            "unicode": "U+1F1E8 U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg"
        },
        {
            "name": "Costa Rica",
            "code": "CR",
            "emoji": "🇨🇷",
            "unicode": "U+1F1E8 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
        },
        {
            "name": "Cuba",
            "code": "CU",
            "emoji": "🇨🇺",
            "unicode": "U+1F1E8 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
        },
        {
            "name": "Cape Verde",
            "code": "CV",
            "emoji": "🇨🇻",
            "unicode": "U+1F1E8 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
        },
        {
            "name": "Curaçao",
            "code": "CW",
            "emoji": "🇨🇼",
            "unicode": "U+1F1E8 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg"
        },
        {
            "name": "Christmas Island",
            "code": "CX",
            "emoji": "🇨🇽",
            "unicode": "U+1F1E8 U+1F1FD",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg"
        },
        {
            "name": "Cyprus",
            "code": "CY",
            "emoji": "🇨🇾",
            "unicode": "U+1F1E8 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
        },
        {
            "name": "Czechia",
            "code": "CZ",
            "emoji": "🇨🇿",
            "unicode": "U+1F1E8 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
        },
        {
            "name": "Germany",
            "code": "DE",
            "emoji": "🇩🇪",
            "unicode": "U+1F1E9 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
        },
        {
            "name": "Diego Garcia",
            "code": "DG",
            "emoji": "🇩🇬",
            "unicode": "U+1F1E9 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg"
        },
        {
            "name": "Djibouti",
            "code": "DJ",
            "emoji": "🇩🇯",
            "unicode": "U+1F1E9 U+1F1EF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
        },
        {
            "name": "Denmark",
            "code": "DK",
            "emoji": "🇩🇰",
            "unicode": "U+1F1E9 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
        },
        {
            "name": "Dominica",
            "code": "DM",
            "emoji": "🇩🇲",
            "unicode": "U+1F1E9 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
        },
        {
            "name": "Dominican Republic",
            "code": "DO",
            "emoji": "🇩🇴",
            "unicode": "U+1F1E9 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
        },
        {
            "name": "Algeria",
            "code": "DZ",
            "emoji": "🇩🇿",
            "unicode": "U+1F1E9 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
        },
        {
            "name": "Ceuta & Melilla",
            "code": "EA",
            "emoji": "🇪🇦",
            "unicode": "U+1F1EA U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg"
        },
        {
            "name": "Ecuador",
            "code": "EC",
            "emoji": "🇪🇨",
            "unicode": "U+1F1EA U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
        },
        {
            "name": "Estonia",
            "code": "EE",
            "emoji": "🇪🇪",
            "unicode": "U+1F1EA U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
        },
        {
            "name": "Egypt",
            "code": "EG",
            "emoji": "🇪🇬",
            "unicode": "U+1F1EA U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
        },
        {
            "name": "Western Sahara",
            "code": "EH",
            "emoji": "🇪🇭",
            "unicode": "U+1F1EA U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg"
        },
        {
            "name": "Eritrea",
            "code": "ER",
            "emoji": "🇪🇷",
            "unicode": "U+1F1EA U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
        },
        {
            "name": "Spain",
            "code": "ES",
            "emoji": "🇪🇸",
            "unicode": "U+1F1EA U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
        },
        {
            "name": "Ethiopia",
            "code": "ET",
            "emoji": "🇪🇹",
            "unicode": "U+1F1EA U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
        },
        {
            "name": "European Union",
            "code": "EU",
            "emoji": "🇪🇺",
            "unicode": "U+1F1EA U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg"
        },
        {
            "name": "Finland",
            "code": "FI",
            "emoji": "🇫🇮",
            "unicode": "U+1F1EB U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
        },
        {
            "name": "Fiji",
            "code": "FJ",
            "emoji": "🇫🇯",
            "unicode": "U+1F1EB U+1F1EF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
        },
        {
            "name": "Falkland Islands",
            "code": "FK",
            "emoji": "🇫🇰",
            "unicode": "U+1F1EB U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
        },
        {
            "name": "Micronesia",
            "code": "FM",
            "emoji": "🇫🇲",
            "unicode": "U+1F1EB U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
        },
        {
            "name": "Faroe Islands",
            "code": "FO",
            "emoji": "🇫🇴",
            "unicode": "U+1F1EB U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
        },
        {
            "name": "France",
            "code": "FR",
            "emoji": "🇫🇷",
            "unicode": "U+1F1EB U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
        },
        {
            "name": "Gabon",
            "code": "GA",
            "emoji": "🇬🇦",
            "unicode": "U+1F1EC U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
        },
        {
            "name": "United Kingdom",
            "code": "GB",
            "emoji": "🇬🇧",
            "unicode": "U+1F1EC U+1F1E7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
        },
        {
            "name": "Grenada",
            "code": "GD",
            "emoji": "🇬🇩",
            "unicode": "U+1F1EC U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
        },
        {
            "name": "Georgia",
            "code": "GE",
            "emoji": "🇬🇪",
            "unicode": "U+1F1EC U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
        },
        {
            "name": "French Guiana",
            "code": "GF",
            "emoji": "🇬🇫",
            "unicode": "U+1F1EC U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
        },
        {
            "name": "Guernsey",
            "code": "GG",
            "emoji": "🇬🇬",
            "unicode": "U+1F1EC U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg"
        },
        {
            "name": "Ghana",
            "code": "GH",
            "emoji": "🇬🇭",
            "unicode": "U+1F1EC U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
        },
        {
            "name": "Gibraltar",
            "code": "GI",
            "emoji": "🇬🇮",
            "unicode": "U+1F1EC U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
        },
        {
            "name": "Greenland",
            "code": "GL",
            "emoji": "🇬🇱",
            "unicode": "U+1F1EC U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
        },
        {
            "name": "Gambia",
            "code": "GM",
            "emoji": "🇬🇲",
            "unicode": "U+1F1EC U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
        },
        {
            "name": "Guinea",
            "code": "GN",
            "emoji": "🇬🇳",
            "unicode": "U+1F1EC U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
        },
        {
            "name": "Guadeloupe",
            "code": "GP",
            "emoji": "🇬🇵",
            "unicode": "U+1F1EC U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
        },
        {
            "name": "Equatorial Guinea",
            "code": "GQ",
            "emoji": "🇬🇶",
            "unicode": "U+1F1EC U+1F1F6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
        },
        {
            "name": "Greece",
            "code": "GR",
            "emoji": "🇬🇷",
            "unicode": "U+1F1EC U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
        },
        {
            "name": "South Georgia & South Sandwich Islands",
            "code": "GS",
            "emoji": "🇬🇸",
            "unicode": "U+1F1EC U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg"
        },
        {
            "name": "Guatemala",
            "code": "GT",
            "emoji": "🇬🇹",
            "unicode": "U+1F1EC U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
        },
        {
            "name": "Guam",
            "code": "GU",
            "emoji": "🇬🇺",
            "unicode": "U+1F1EC U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
        },
        {
            "name": "Guinea-Bissau",
            "code": "GW",
            "emoji": "🇬🇼",
            "unicode": "U+1F1EC U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
        },
        {
            "name": "Guyana",
            "code": "GY",
            "emoji": "🇬🇾",
            "unicode": "U+1F1EC U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
        },
        {
            "name": "Hong Kong SAR China",
            "code": "HK",
            "emoji": "🇭🇰",
            "unicode": "U+1F1ED U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
        },
        {
            "name": "Heard & McDonald Islands",
            "code": "HM",
            "emoji": "🇭🇲",
            "unicode": "U+1F1ED U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg"
        },
        {
            "name": "Honduras",
            "code": "HN",
            "emoji": "🇭🇳",
            "unicode": "U+1F1ED U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
        },
        {
            "name": "Croatia",
            "code": "HR",
            "emoji": "🇭🇷",
            "unicode": "U+1F1ED U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
        },
        {
            "name": "Haiti",
            "code": "HT",
            "emoji": "🇭🇹",
            "unicode": "U+1F1ED U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
        },
        {
            "name": "Hungary",
            "code": "HU",
            "emoji": "🇭🇺",
            "unicode": "U+1F1ED U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
        },
        {
            "name": "Canary Islands",
            "code": "IC",
            "emoji": "🇮🇨",
            "unicode": "U+1F1EE U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg"
        },
        {
            "name": "Indonesia",
            "code": "ID",
            "emoji": "🇮🇩",
            "unicode": "U+1F1EE U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
        },
        {
            "name": "Ireland",
            "code": "IE",
            "emoji": "🇮🇪",
            "unicode": "U+1F1EE U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
        },
        {
            "name": "Israel",
            "code": "IL",
            "emoji": "🇮🇱",
            "unicode": "U+1F1EE U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
        },
        {
            "name": "Isle of Man",
            "code": "IM",
            "emoji": "🇮🇲",
            "unicode": "U+1F1EE U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg"
        },
        {
            "name": "India",
            "code": "IN",
            "emoji": "🇮🇳",
            "unicode": "U+1F1EE U+1F1F3",
            "image": "../assets/svg-country-flags/curve-flags/IN.png"
        },
        {
            "name": "British Indian Ocean Territory",
            "code": "IO",
            "emoji": "🇮🇴",
            "unicode": "U+1F1EE U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg"
        },
        {
            "name": "Iraq",
            "code": "IQ",
            "emoji": "🇮🇶",
            "unicode": "U+1F1EE U+1F1F6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
        },
        {
            "name": "Iran",
            "code": "IR",
            "emoji": "🇮🇷",
            "unicode": "U+1F1EE U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
        },
        {
            "name": "Iceland",
            "code": "IS",
            "emoji": "🇮🇸",
            "unicode": "U+1F1EE U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
        },
        {
            "name": "Italy",
            "code": "IT",
            "emoji": "🇮🇹",
            "unicode": "U+1F1EE U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
        },
        {
            "name": "Jersey",
            "code": "JE",
            "emoji": "🇯🇪",
            "unicode": "U+1F1EF U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg"
        },
        {
            "name": "Jamaica",
            "code": "JM",
            "emoji": "🇯🇲",
            "unicode": "U+1F1EF U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
        },
        {
            "name": "Jordan",
            "code": "JO",
            "emoji": "🇯🇴",
            "unicode": "U+1F1EF U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
        },
        {
            "name": "Japan",
            "code": "JP",
            "emoji": "🇯🇵",
            "unicode": "U+1F1EF U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
        },
        {
            "name": "Kenya",
            "code": "KE",
            "emoji": "🇰🇪",
            "unicode": "U+1F1F0 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
        },
        {
            "name": "Kyrgyzstan",
            "code": "KG",
            "emoji": "🇰🇬",
            "unicode": "U+1F1F0 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
        },
        {
            "name": "Cambodia",
            "code": "KH",
            "emoji": "🇰🇭",
            "unicode": "U+1F1F0 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
        },
        {
            "name": "Kiribati",
            "code": "KI",
            "emoji": "🇰🇮",
            "unicode": "U+1F1F0 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
        },
        {
            "name": "Comoros",
            "code": "KM",
            "emoji": "🇰🇲",
            "unicode": "U+1F1F0 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
        },
        {
            "name": "St. Kitts & Nevis",
            "code": "KN",
            "emoji": "🇰🇳",
            "unicode": "U+1F1F0 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
        },
        {
            "name": "North Korea",
            "code": "KP",
            "emoji": "🇰🇵",
            "unicode": "U+1F1F0 U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg"
        },
        {
            "name": "South Korea",
            "code": "KR",
            "emoji": "🇰🇷",
            "unicode": "U+1F1F0 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
        },
        {
            "name": "Kuwait",
            "code": "KW",
            "emoji": "🇰🇼",
            "unicode": "U+1F1F0 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
        },
        {
            "name": "Cayman Islands",
            "code": "KY",
            "emoji": "🇰🇾",
            "unicode": "U+1F1F0 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
        },
        {
            "name": "Kazakhstan",
            "code": "KZ",
            "emoji": "🇰🇿",
            "unicode": "U+1F1F0 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
        },
        {
            "name": "Laos",
            "code": "LA",
            "emoji": "🇱🇦",
            "unicode": "U+1F1F1 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
        },
        {
            "name": "Lebanon",
            "code": "LB",
            "emoji": "🇱🇧",
            "unicode": "U+1F1F1 U+1F1E7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
        },
        {
            "name": "St. Lucia",
            "code": "LC",
            "emoji": "🇱🇨",
            "unicode": "U+1F1F1 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
        },
        {
            "name": "Liechtenstein",
            "code": "LI",
            "emoji": "🇱🇮",
            "unicode": "U+1F1F1 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
        },
        {
            "name": "Sri Lanka",
            "code": "LK",
            "emoji": "🇱🇰",
            "unicode": "U+1F1F1 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
        },
        {
            "name": "Liberia",
            "code": "LR",
            "emoji": "🇱🇷",
            "unicode": "U+1F1F1 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
        },
        {
            "name": "Lesotho",
            "code": "LS",
            "emoji": "🇱🇸",
            "unicode": "U+1F1F1 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
        },
        {
            "name": "Lithuania",
            "code": "LT",
            "emoji": "🇱🇹",
            "unicode": "U+1F1F1 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
        },
        {
            "name": "Luxembourg",
            "code": "LU",
            "emoji": "🇱🇺",
            "unicode": "U+1F1F1 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
        },
        {
            "name": "Latvia",
            "code": "LV",
            "emoji": "🇱🇻",
            "unicode": "U+1F1F1 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
        },
        {
            "name": "Libya",
            "code": "LY",
            "emoji": "🇱🇾",
            "unicode": "U+1F1F1 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
        },
        {
            "name": "Morocco",
            "code": "MA",
            "emoji": "🇲🇦",
            "unicode": "U+1F1F2 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
        },
        {
            "name": "Monaco",
            "code": "MC",
            "emoji": "🇲🇨",
            "unicode": "U+1F1F2 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
        },
        {
            "name": "Moldova",
            "code": "MD",
            "emoji": "🇲🇩",
            "unicode": "U+1F1F2 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
        },
        {
            "name": "Montenegro",
            "code": "ME",
            "emoji": "🇲🇪",
            "unicode": "U+1F1F2 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
        },
        {
            "name": "St. Martin",
            "code": "MF",
            "emoji": "🇲🇫",
            "unicode": "U+1F1F2 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg"
        },
        {
            "name": "Madagascar",
            "code": "MG",
            "emoji": "🇲🇬",
            "unicode": "U+1F1F2 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
        },
        {
            "name": "Marshall Islands",
            "code": "MH",
            "emoji": "🇲🇭",
            "unicode": "U+1F1F2 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
        },
        {
            "name": "North Macedonia",
            "code": "MK",
            "emoji": "🇲🇰",
            "unicode": "U+1F1F2 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
        },
        {
            "name": "Mali",
            "code": "ML",
            "emoji": "🇲🇱",
            "unicode": "U+1F1F2 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
        },
        {
            "name": "Myanmar (Burma)",
            "code": "MM",
            "emoji": "🇲🇲",
            "unicode": "U+1F1F2 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
        },
        {
            "name": "Mongolia",
            "code": "MN",
            "emoji": "🇲🇳",
            "unicode": "U+1F1F2 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
        },
        {
            "name": "Macao SAR China",
            "code": "MO",
            "emoji": "🇲🇴",
            "unicode": "U+1F1F2 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
        },
        {
            "name": "Northern Mariana Islands",
            "code": "MP",
            "emoji": "🇲🇵",
            "unicode": "U+1F1F2 U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
        },
        {
            "name": "Martinique",
            "code": "MQ",
            "emoji": "🇲🇶",
            "unicode": "U+1F1F2 U+1F1F6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
        },
        {
            "name": "Mauritania",
            "code": "MR",
            "emoji": "🇲🇷",
            "unicode": "U+1F1F2 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
        },
        {
            "name": "Montserrat",
            "code": "MS",
            "emoji": "🇲🇸",
            "unicode": "U+1F1F2 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
        },
        {
            "name": "Malta",
            "code": "MT",
            "emoji": "🇲🇹",
            "unicode": "U+1F1F2 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
        },
        {
            "name": "Mauritius",
            "code": "MU",
            "emoji": "🇲🇺",
            "unicode": "U+1F1F2 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
        },
        {
            "name": "Maldives",
            "code": "MV",
            "emoji": "🇲🇻",
            "unicode": "U+1F1F2 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
        },
        {
            "name": "Malawi",
            "code": "MW",
            "emoji": "🇲🇼",
            "unicode": "U+1F1F2 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
        },
        {
            "name": "Mexico",
            "code": "MX",
            "emoji": "🇲🇽",
            "unicode": "U+1F1F2 U+1F1FD",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
        },
        {
            "name": "Malaysia",
            "code": "MY",
            "emoji": "🇲🇾",
            "unicode": "U+1F1F2 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
        },
        {
            "name": "Mozambique",
            "code": "MZ",
            "emoji": "🇲🇿",
            "unicode": "U+1F1F2 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
        },
        {
            "name": "Namibia",
            "code": "NA",
            "emoji": "🇳🇦",
            "unicode": "U+1F1F3 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
        },
        {
            "name": "New Caledonia",
            "code": "NC",
            "emoji": "🇳🇨",
            "unicode": "U+1F1F3 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
        },
        {
            "name": "Niger",
            "code": "NE",
            "emoji": "🇳🇪",
            "unicode": "U+1F1F3 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
        },
        {
            "name": "Norfolk Island",
            "code": "NF",
            "emoji": "🇳🇫",
            "unicode": "U+1F1F3 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
        },
        {
            "name": "Nigeria",
            "code": "NG",
            "emoji": "🇳🇬",
            "unicode": "U+1F1F3 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
        },
        {
            "name": "Nicaragua",
            "code": "NI",
            "emoji": "🇳🇮",
            "unicode": "U+1F1F3 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
        },
        {
            "name": "Netherlands",
            "code": "NL",
            "emoji": "🇳🇱",
            "unicode": "U+1F1F3 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
        },
        {
            "name": "Norway",
            "code": "NO",
            "emoji": "🇳🇴",
            "unicode": "U+1F1F3 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
        },
        {
            "name": "Nepal",
            "code": "NP",
            "emoji": "🇳🇵",
            "unicode": "U+1F1F3 U+1F1F5",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
        },
        {
            "name": "Nauru",
            "code": "NR",
            "emoji": "🇳🇷",
            "unicode": "U+1F1F3 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
        },
        {
            "name": "Niue",
            "code": "NU",
            "emoji": "🇳🇺",
            "unicode": "U+1F1F3 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
        },
        {
            "name": "New Zealand",
            "code": "NZ",
            "emoji": "🇳🇿",
            "unicode": "U+1F1F3 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
        },
        {
            "name": "Oman",
            "code": "OM",
            "emoji": "🇴🇲",
            "unicode": "U+1F1F4 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
        },
        {
            "name": "Panama",
            "code": "PA",
            "emoji": "🇵🇦",
            "unicode": "U+1F1F5 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
        },
        {
            "name": "Peru",
            "code": "PE",
            "emoji": "🇵🇪",
            "unicode": "U+1F1F5 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
        },
        {
            "name": "French Polynesia",
            "code": "PF",
            "emoji": "🇵🇫",
            "unicode": "U+1F1F5 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
        },
        {
            "name": "Papua New Guinea",
            "code": "PG",
            "emoji": "🇵🇬",
            "unicode": "U+1F1F5 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
        },
        {
            "name": "Philippines",
            "code": "PH",
            "emoji": "🇵🇭",
            "unicode": "U+1F1F5 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
        },
        {
            "name": "Pakistan",
            "code": "PK",
            "emoji": "🇵🇰",
            "unicode": "U+1F1F5 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
        },
        {
            "name": "Poland",
            "code": "PL",
            "emoji": "🇵🇱",
            "unicode": "U+1F1F5 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
        },
        {
            "name": "St. Pierre & Miquelon",
            "code": "PM",
            "emoji": "🇵🇲",
            "unicode": "U+1F1F5 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
        },
        {
            "name": "Pitcairn Islands",
            "code": "PN",
            "emoji": "🇵🇳",
            "unicode": "U+1F1F5 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg"
        },
        {
            "name": "Puerto Rico",
            "code": "PR",
            "emoji": "🇵🇷",
            "unicode": "U+1F1F5 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
        },
        {
            "name": "Palestinian Territories",
            "code": "PS",
            "emoji": "🇵🇸",
            "unicode": "U+1F1F5 U+1F1F8",
            "image": "../assets/svg-country-flags/curve-flags/PS.png"
        },
        {
            "name": "Portugal",
            "code": "PT",
            "emoji": "🇵🇹",
            "unicode": "U+1F1F5 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
        },
        {
            "name": "Palau",
            "code": "PW",
            "emoji": "🇵🇼",
            "unicode": "U+1F1F5 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
        },
        {
            "name": "Paraguay",
            "code": "PY",
            "emoji": "🇵🇾",
            "unicode": "U+1F1F5 U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
        },
        {
            "name": "Qatar",
            "code": "QA",
            "emoji": "🇶🇦",
            "unicode": "U+1F1F6 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
        },
        {
            "name": "Réunion",
            "code": "RE",
            "emoji": "🇷🇪",
            "unicode": "U+1F1F7 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
        },
        {
            "name": "Romania",
            "code": "RO",
            "emoji": "🇷🇴",
            "unicode": "U+1F1F7 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
        },
        {
            "name": "Serbia",
            "code": "RS",
            "emoji": "🇷🇸",
            "unicode": "U+1F1F7 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
        },
        {
            "name": "Russia",
            "code": "RU",
            "emoji": "🇷🇺",
            "unicode": "U+1F1F7 U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
        },
        {
            "name": "Rwanda",
            "code": "RW",
            "emoji": "🇷🇼",
            "unicode": "U+1F1F7 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
        },
        {
            "name": "Saudi Arabia",
            "code": "SA",
            "emoji": "🇸🇦",
            "unicode": "U+1F1F8 U+1F1E6",
            "image": "../assets/svg-country-flags/curve-flags/SA.png"
        },
        {
            "name": "Solomon Islands",
            "code": "SB",
            "emoji": "🇸🇧",
            "unicode": "U+1F1F8 U+1F1E7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
        },
        {
            "name": "Seychelles",
            "code": "SC",
            "emoji": "🇸🇨",
            "unicode": "U+1F1F8 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
        },
        {
            "name": "Sudan",
            "code": "SD",
            "emoji": "🇸🇩",
            "unicode": "U+1F1F8 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
        },
        {
            "name": "Sweden",
            "code": "SE",
            "emoji": "🇸🇪",
            "unicode": "U+1F1F8 U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
        },
        {
            "name": "Singapore",
            "code": "SG",
            "emoji": "🇸🇬",
            "unicode": "U+1F1F8 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
        },
        {
            "name": "St. Helena",
            "code": "SH",
            "emoji": "🇸🇭",
            "unicode": "U+1F1F8 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
        },
        {
            "name": "Slovenia",
            "code": "SI",
            "emoji": "🇸🇮",
            "unicode": "U+1F1F8 U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
        },
        {
            "name": "Svalbard & Jan Mayen",
            "code": "SJ",
            "emoji": "🇸🇯",
            "unicode": "U+1F1F8 U+1F1EF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg"
        },
        {
            "name": "Slovakia",
            "code": "SK",
            "emoji": "🇸🇰",
            "unicode": "U+1F1F8 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
        },
        {
            "name": "Sierra Leone",
            "code": "SL",
            "emoji": "🇸🇱",
            "unicode": "U+1F1F8 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
        },
        {
            "name": "San Marino",
            "code": "SM",
            "emoji": "🇸🇲",
            "unicode": "U+1F1F8 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
        },
        {
            "name": "Senegal",
            "code": "SN",
            "emoji": "🇸🇳",
            "unicode": "U+1F1F8 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
        },
        {
            "name": "Somalia",
            "code": "SO",
            "emoji": "🇸🇴",
            "unicode": "U+1F1F8 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
        },
        {
            "name": "Suriname",
            "code": "SR",
            "emoji": "🇸🇷",
            "unicode": "U+1F1F8 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
        },
        {
            "name": "South Sudan",
            "code": "SS",
            "emoji": "🇸🇸",
            "unicode": "U+1F1F8 U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg"
        },
        {
            "name": "São Tomé & Príncipe",
            "code": "ST",
            "emoji": "🇸🇹",
            "unicode": "U+1F1F8 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
        },
        {
            "name": "El Salvador",
            "code": "SV",
            "emoji": "🇸🇻",
            "unicode": "U+1F1F8 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
        },
        {
            "name": "Sint Maarten",
            "code": "SX",
            "emoji": "🇸🇽",
            "unicode": "U+1F1F8 U+1F1FD",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg"
        },
        {
            "name": "Syria",
            "code": "SY",
            "emoji": "🇸🇾",
            "unicode": "U+1F1F8 U+1F1FE",
            "image": "../assets/svg-country-flags/curve-flags/SY.png"
        },
        {
            "name": "Eswatini",
            "code": "SZ",
            "emoji": "🇸🇿",
            "unicode": "U+1F1F8 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
        },
        {
            "name": "Tristan da Cunha",
            "code": "TA",
            "emoji": "🇹🇦",
            "unicode": "U+1F1F9 U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg"
        },
        {
            "name": "Turks & Caicos Islands",
            "code": "TC",
            "emoji": "🇹🇨",
            "unicode": "U+1F1F9 U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
        },
        {
            "name": "Chad",
            "code": "TD",
            "emoji": "🇹🇩",
            "unicode": "U+1F1F9 U+1F1E9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
        },
        {
            "name": "French Southern Territories",
            "code": "TF",
            "emoji": "🇹🇫",
            "unicode": "U+1F1F9 U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg"
        },
        {
            "name": "Togo",
            "code": "TG",
            "emoji": "🇹🇬",
            "unicode": "U+1F1F9 U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
        },
        {
            "name": "Thailand",
            "code": "TH",
            "emoji": "🇹🇭",
            "unicode": "U+1F1F9 U+1F1ED",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
        },
        {
            "name": "Tajikistan",
            "code": "TJ",
            "emoji": "🇹🇯",
            "unicode": "U+1F1F9 U+1F1EF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
        },
        {
            "name": "Tokelau",
            "code": "TK",
            "emoji": "🇹🇰",
            "unicode": "U+1F1F9 U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
        },
        {
            "name": "Timor-Leste",
            "code": "TL",
            "emoji": "🇹🇱",
            "unicode": "U+1F1F9 U+1F1F1",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
        },
        {
            "name": "Turkmenistan",
            "code": "TM",
            "emoji": "🇹🇲",
            "unicode": "U+1F1F9 U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
        },
        {
            "name": "Tunisia",
            "code": "TN",
            "emoji": "🇹🇳",
            "unicode": "U+1F1F9 U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
        },
        {
            "name": "Tonga",
            "code": "TO",
            "emoji": "🇹🇴",
            "unicode": "U+1F1F9 U+1F1F4",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
        },
        {
            "name": "Turkey",
            "code": "TR",
            "emoji": "🇹🇷",
            "unicode": "U+1F1F9 U+1F1F7",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
        },
        {
            "name": "Trinidad & Tobago",
            "code": "TT",
            "emoji": "🇹🇹",
            "unicode": "U+1F1F9 U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
        },
        {
            "name": "Tuvalu",
            "code": "TV",
            "emoji": "🇹🇻",
            "unicode": "U+1F1F9 U+1F1FB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
        },
        {
            "name": "Taiwan",
            "code": "TW",
            "emoji": "🇹🇼",
            "unicode": "U+1F1F9 U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
        },
        {
            "name": "Tanzania",
            "code": "TZ",
            "emoji": "🇹🇿",
            "unicode": "U+1F1F9 U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
        },
        {
            "name": "Ukraine",
            "code": "UA",
            "emoji": "🇺🇦",
            "unicode": "U+1F1FA U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
        },
        {
            "name": "Uganda",
            "code": "UG",
            "emoji": "🇺🇬",
            "unicode": "U+1F1FA U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
        },
        {
            "name": "U.S. Outlying Islands",
            "code": "UM",
            "emoji": "🇺🇲",
            "unicode": "U+1F1FA U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg"
        },
        {
            "name": "United Nations",
            "code": "UN",
            "emoji": "🇺🇳",
            "unicode": "U+1F1FA U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg"
        },
        {
            "name": "United States",
            "code": "US",
            "emoji": "🇺🇸",
            "unicode": "U+1F1FA U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
        },
        {
            "name": "Uruguay",
            "code": "UY",
            "emoji": "🇺🇾",
            "unicode": "U+1F1FA U+1F1FE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
        },
        {
            "name": "Uzbekistan",
            "code": "UZ",
            "emoji": "🇺🇿",
            "unicode": "U+1F1FA U+1F1FF",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
        },
        {
            "name": "Vatican City",
            "code": "VA",
            "emoji": "🇻🇦",
            "unicode": "U+1F1FB U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
        },
        {
            "name": "St. Vincent & Grenadines",
            "code": "VC",
            "emoji": "🇻🇨",
            "unicode": "U+1F1FB U+1F1E8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
        },
        {
            "name": "Venezuela",
            "code": "VE",
            "emoji": "🇻🇪",
            "unicode": "U+1F1FB U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
        },
        {
            "name": "British Virgin Islands",
            "code": "VG",
            "emoji": "🇻🇬",
            "unicode": "U+1F1FB U+1F1EC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
        },
        {
            "name": "U.S. Virgin Islands",
            "code": "VI",
            "emoji": "🇻🇮",
            "unicode": "U+1F1FB U+1F1EE",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
        },
        {
            "name": "Vietnam",
            "code": "VN",
            "emoji": "🇻🇳",
            "unicode": "U+1F1FB U+1F1F3",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
        },
        {
            "name": "Vanuatu",
            "code": "VU",
            "emoji": "🇻🇺",
            "unicode": "U+1F1FB U+1F1FA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
        },
        {
            "name": "Wallis & Futuna",
            "code": "WF",
            "emoji": "🇼🇫",
            "unicode": "U+1F1FC U+1F1EB",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
        },
        {
            "name": "Samoa",
            "code": "WS",
            "emoji": "🇼🇸",
            "unicode": "U+1F1FC U+1F1F8",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
        },
        {
            "name": "Kosovo",
            "code": "XK",
            "emoji": "🇽🇰",
            "unicode": "U+1F1FD U+1F1F0",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg"
        },
        {
            "name": "Yemen",
            "code": "YE",
            "emoji": "🇾🇪",
            "unicode": "U+1F1FE U+1F1EA",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
        },
        {
            "name": "Mayotte",
            "code": "YT",
            "emoji": "🇾🇹",
            "unicode": "U+1F1FE U+1F1F9",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
        },
        {
            "name": "South Africa",
            "code": "ZA",
            "emoji": "🇿🇦",
            "unicode": "U+1F1FF U+1F1E6",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
        },
        {
            "name": "Zambia",
            "code": "ZM",
            "emoji": "🇿🇲",
            "unicode": "U+1F1FF U+1F1F2",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
        },
        {
            "name": "Zimbabwe",
            "code": "ZW",
            "emoji": "🇿🇼",
            "unicode": "U+1F1FF U+1F1FC",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
        },
        {
            "name": "England",
            "code": "ENGLAND",
            "emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
            "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg"
        },
        {
            "name": "Scotland",
            "code": "SCOTLAND",
            "emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
            "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg"
        },
        {
            "name": "Wales",
            "code": "WALES",
            "emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
            "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
            "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg"
        }
    ];
}

