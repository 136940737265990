import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uc',
  templateUrl: './uc.component.html',
  styleUrls: ['./uc.component.css']
})
export class UcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
