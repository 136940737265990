import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { GlobalLangService } from '../../services/global-lang.service';

@Component({
  selector: 'app-coach-filter',
  templateUrl: './coach-filter.component.html',
  styleUrls: ['./coach-filter.component.css']
})
export class CoachFilterComponent implements OnInit {
  coachForm: FormGroup;
  ageGroupData: any[] = [];
  translate: any;
  YobList: any[] = [];
  isYobSupported = false;
  academyResponsibleFor: string = '';
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<CoachFilterComponent>,
    private globalService: GlobalLangService,
    public api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.COACHES.FILTER) {
        this.translate = data.COACHES.FILTER;
      }
    });
  }

  ngOnInit() {
    this.academyResponsibleFor = localStorage.getItem("academyResponsibleFor");
    this.coachForm = this.fb.group({
      contractStatus: [''],
      ageGroup: [''],
      yob: ['']
    });
    this.createForm();
    this.onPageLoad();
  }
  onPageLoad() {
    const ageGroupObj: any = {};
    ageGroupObj.pageNo = 1;
    ageGroupObj.acadyid = localStorage.getItem("acadyid");
    /*ageGroupObj.remoteurl = this.api.apiUrl.rmgetAgeGroups;
    ageGroupObj.userEmail = localStorage.getItem('emailaddress');
    ageGroupObj.methodtype = "GET";
    forkJoin(
      {
        ageGroup: this.api.RMAcademyPostData(this.api.apiUrl.apitokenforThirdparty, ageGroupObj, true)
      }
    ).subscribe((response: any) => {

      if (response.ageGroup) {
        this.ageGroupData = response.ageGroup.data.data;
      }
    });*/
    const obj: any = {};
    this.api.getData('academy/getDashboard', obj, true)
      .subscribe(res => {
        if (res.statusCode == 200) {
          if (res.data.academy[0].ageGroup.length > 0) {
            this.isYobSupported = false;
            this.ageGroupData = res.data.academy[0].ageGroup;
            this.ageGroupData.sort(function (a, b) {
              return a.id - b.id;
            });
          }
          else if (res.data.academy[0].yob.length > 0) {
            this.isYobSupported = true;
            this.YobList = res.data.academy[0].yob;
          }
        }
      });
  }
  createForm() {
    /*this.coachForm = this.fb.group({
      contractStatus: [''],
      ageGroup: ['']
    });*/
    if (this.data.contractStatus) {
      this.coachForm.patchValue({ contractStatus: this.data.contractStatus });
    }
    if (this.data.ageGroup) {
      this.coachForm.patchValue({ ageGroup: this.data.ageGroup });
    }
    if (this.data.yob) {
      this.coachForm.patchValue({ yob: this.data.yob });
    }
  }
  submitCoach() {
    if (this.coachForm.valid) {
      this.dialogRef.close(this.coachForm.value);
    }
  }
  resetForm() {
    this.coachForm.reset();
    this.dialogRef.close(this.coachForm.value);
  }
  close() {
    this.dialogRef.close(); return false;
  }
}
