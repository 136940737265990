import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalLangService } from 'src/app/services/global-lang.service';

@Component({
  selector: 'app-sales-filter',
  templateUrl: './sales-filter.component.html',
  styleUrls: ['./sales-filter.component.css']
})
export class SalesFilterComponent implements OnInit {
  salesForm: FormGroup;
  translate: any;
  constructor(
    public dialogRef: MatDialogRef<SalesFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalLangService
  ) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.FILTERPLAYERS) {
        this.translate = data.FILTERPLAYERS;
      }
    });
  }

  ngOnInit() {
  }

  submitPlayer() {
    if (this.salesForm.valid) {
      this.dialogRef.close(this.salesForm.value);
    }
  }
  resetForm() {
    this.salesForm.reset();
    this.dialogRef.close(this.salesForm.value);
  }
  close() {
    this.dialogRef.close(); return false;
  }

}
