import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
@Component({
  selector: 'app-academy-reject',
  templateUrl: './academy-reject.component.html',
  styleUrls: ['./academy-reject.component.css']
})
export class AcademyRejectComponent implements OnInit {
  playerForm: FormGroup;
  translate: any;
  isEnglish = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AcademyRejectComponent>,
    public api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any) {
     }

  ngOnInit() {
    this.createForm();
  }


  
  createForm() {
    this.playerForm = this.fb.group({
      description: ['', Validators.required]
    });

  }
  submitPlayer() {
    if (this.playerForm.valid) {
      this.dialogRef.close(this.playerForm.value);
    }
  }
  close(){
     this.dialogRef.close(); return false;
  }
}
