/*Live*/
/*export const environment = {
  production: false,
  //remote
  apiBaseUrl: 'https://livenodev2.grintafy.com/', 
  acadyapiBaseUrl:'https://livenodev2.grintafy.com/',
  fileUrl:'https://academy-api.grintafy.com/storage/',
 RMapiBaseUrl:"https://livenodev2.grintafy.com/",
   
};*/

/*Test*/
export const environment = {
  production: false,
  //remote
  apiBaseUrl: 'https://dev-app.grintafy.com/', 
  acadyapiBaseUrl:'https://dev-app.grintafy.com/',
  fileUrl:'https://academy-api.grintafy.com/storage/',
 RMapiBaseUrl:"https://dev-app.grintafy.com/",
   
};


