import {Component, OnInit} from '@angular/core';
import {TableCommonModel} from '../../shared/models/table.common.model';
import {ApiService} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {

    bsValue = new Date();
    bsRangeValue: Date[];
    maxDate = new Date();
    myModel: any;
    referralCode = '';
    form: FormGroup;

    constructor(
        public api: ApiService,
        private fb: FormBuilder,
    ) {
        this.myModel = new TableCommonModel();
        this.maxDate.setDate(this.maxDate.getDate() - 30);
        this.bsRangeValue = [this.bsValue, this.maxDate];

        this.form = this.fb.group({
            referralCode: ['', Validators.required],
        });
    }

    ngOnInit() {
    }

    pageChanged(event: any): void {
        this.myModel.currentPage = event.page;
        this.getList();
    }

    getList() {

        // if (!this.referralCode) {
        //     this.api.toastr.error('Please enter referral code');
        //     return;
        // }
        if (this.form.valid) {
            const skip: any = (this.myModel.currentPage - 1) * 20;
            const obj: any = {
                skip: skip ? skip : '0',
                limit: 20,
                referralCode: this.form.value.referralCode
            };

            this.api.getData(this.api.apiUrl.fetchReferrals, obj, true)
                .subscribe(res => {
                        this.myModel.allData = res.data.referrals;
                        this.myModel.totalItems = res.data.count;
                        // this.changeDetect.detectChanges();
                        // this.router.navigate(['/owners'], {queryParams: {pageNo: this.myModel.currentPage}});
                    },
                    () => {
                    });
        } else {
            this.api.setTouched(this.form);
        }
    }


}
