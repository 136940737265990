import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalLangService } from '../../services/global-lang.service';

@Component({
  selector: 'app-acadyformd',
  templateUrl: './acadyformd.component.html',
  styleUrls: ['./acadyformd.component.css']
})
export class AcadyformdComponent implements OnInit, OnDestroy {
  email: string;
  translate: any;

  constructor(

    private router: Router,
    private globalService: GlobalLangService
  ) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.THANKYOUREGISTERATION) {
          this.translate = data.THANKYOUREGISTERATION;
      }
  })
  }

  ngOnInit() {

    this.email = localStorage.getItem('registeredEmail') || 'registered email'
  }

  btmovetologin() {
    this.router.navigate(['/login']);
  }
  ngOnDestroy() {
    localStorage.removeItem('registeredEmail')
  }
}