import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { GlobalLangService } from '../../services/global-lang.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
  href: any;
  public form: FormGroup;
  public loader = false;
  verifiedSuccess: boolean = false;
  notMacthed: boolean = false;
  translate: any;
  constructor(
    public api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    public globalservice : GlobalLangService
  ) {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      passwordConf: ['', Validators.required]
    });
    this.globalservice.languageDataObj.subscribe((data: any) => {
      if (data.LOGIN) {
        this.translate = data.VERIFICATION;
      }
    });
  }

  ngOnInit() { 
    this.href = this.router.url;
    let fromEmail = this.href.includes("id");
    if (fromEmail) {
      let id = this.href.slice(17)
      this.api.postData(this.api.apiUrl.verifyAccount, { 'id': id })
        .subscribe(serverResponse => {
          if (serverResponse.statusCode == 200) {
            this.verifiedSuccess = true;
            //setTimeout(() => {
              this.api.custom_toastr_success_msg(this.translate.accountActivatedSuccess);
              this.router.navigate(['/login']);
            //} , 10000)
          } else {
            this.verifiedSuccess = false;
            let msgdata = serverResponse.msg;
            this.api.custom_toastr_error_msg(msgdata);
            this.router.navigate(['/login']);
            this.loader = false;
          }
        },
          () => {
            this.loader = false;
            this.router.navigate(['/login']);
          });
    }
  }
}
