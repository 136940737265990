
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Subject, forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GlobalLangService } from '../../services/global-lang.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-acadyform',
    templateUrl: './acadyform.component.html',
    styleUrls: ['./acadyform.component.css']
})
export class AcadyformComponent implements OnInit {
    @ViewChild('search', { static: true }) search: ElementRef;
    public countryCodefilter: FormControl = new FormControl();
    public countryfilter: FormControl = new FormControl();
    public cityfilter: FormControl = new FormControl();
    form: FormGroup;
    countryData = [];
    cityData = [];
    protected _onDestroy = new Subject<void>();
    emailErrorMsg: any;
    countryFilteredData = [];
    cityFilteredData = [];
    countryNameList = [];
    isSubmitted = false;
    translate: any;
    isEnglish = true;
    digits9 = false;
    digits8 = true;
    registeredEmail = '';
    constructor(
        private fb: FormBuilder,
        private router: Router,
        public api: ApiService,
        public modalService: BsModalService,
        public globalservice: GlobalLangService
    ) {
        this.form = this.fb.group({
            name: ['', Validators.required],
            academyName: ['', Validators.required],
            country: ['', Validators.required],
            city: ['', Validators.required],
            countryCode: ['', Validators.required],
            mobileNo: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])],
            //email: ['', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])],
            password: ['', Validators.required],
        });
        this.globalservice.languageDataObj.subscribe((data: any) => {
            if (data.LOGIN) {
                this.translate = data.SIGNUP;
            }
        });
        this.isEnglish = localStorage.getItem('language') === 'en' ? true : false;
    }
    ngOnInit() {
        this.countryNameList = this.api.countryNameList;
        localStorage.setItem('countryNameList', JSON.stringify(this.countryNameList))
        this.onPageLoad();

        // country code search
        this.countryCodefilter.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {

                let search = this.countryCodefilter.value.toLowerCase();
                // 


                if (search) {
                    let dataall = JSON.parse(localStorage.getItem('countryNameList'))
                    let filteredData = [];
                    this.countryNameList = [];
                    dataall.forEach(element => {

                        if (element.countryName.toLowerCase().indexOf(search) > -1) {
                            filteredData.push(element)
                        }
                        else {
                            // alert('2')
                            // alert('Not exist   '+ element.countryName)
                        }

                    });
                    this.countryNameList = filteredData
                }
                else {
                    this.countryNameList = JSON.parse(localStorage.getItem('countryNameList'))
                }
            });

        // country name search
        this.countryfilter.valueChanges
            .subscribe(() => {

                let search = this.countryfilter.value.toLowerCase();
                //alert(search);

                let dataall = JSON.parse(localStorage.getItem('countryData'))
                if (dataall == null) {
                    dataall = this.countryFilteredData;
                }

                if (search) {
                    let filteredData = [];
                    this.countryData = [];
                    dataall.forEach(element => {
                        if (this.isEnglish) {
                            if (element.en.toLowerCase().indexOf(search) > -1) {
                                filteredData.push(element)
                            }
                            else {
                                // alert('2')
                                // alert('Not exist   '+ element.countryName)
                            }
                        }
                        else {
                            if (element.ar.toLowerCase().indexOf(search) > -1) {
                                filteredData.push(element)
                            }
                            else {
                                // alert('2')
                                // alert('Not exist   '+ element.countryName)
                            }
                        }

                    });
                    this.countryFilteredData = filteredData
                }
                else {
                    this.countryFilteredData = JSON.parse(localStorage.getItem('countryData'))
                }
            });

        // city name serach
        this.cityfilter.valueChanges
            .subscribe(() => {

                let search = this.cityfilter.value.toLowerCase();
                // 
                let dataall = JSON.parse(localStorage.getItem('cityData'))


                if (search) {
                    let filteredData = [];
                    this.cityFilteredData = [];
                    dataall.forEach(element => {
                        if (this.isEnglish) {
                            if (element.en.toLowerCase().indexOf(search) > -1) {
                                filteredData.push(element)
                            }
                            else {
                                // alert('2')
                                // alert('Not exist   '+ element.countryName)
                            }
                        }
                        else {
                            if (element.ar.toLowerCase().indexOf(search) > -1) {
                                filteredData.push(element)
                            }
                            else {
                                // alert('2')
                                // alert('Not exist   '+ element.countryName)
                            }
                        }

                    });
                    this.cityFilteredData = filteredData
                }
                else {
                    this.cityFilteredData = JSON.parse(localStorage.getItem('cityData'))
                }
            });

    }

    checkLanguage() {
        this.isEnglish = localStorage.getItem('language') === 'en' ? true : false;
    }
    onCountryChange(data) {

        if (data && this.countryFilteredData) {
            this.form.controls.city.reset();
            this.form.controls.country
            this.countryFilteredData.forEach(country => {
                if (country._id == data) {

                    this.acadycitiesFun(country._id)
                }
            });
        }

    }

    acadycitiesFun(data?) {

        let apiobj: any = {};
        //apiobj.remoteurl = this.api.apiUrl.rmgetCities;
        apiobj = {
            'countryId': data || null
        }
        // apiobj.methodtype = "GET";


        this.api.getData('academy/getCities', apiobj, true)
            .subscribe(res => {

                this.cityFilteredData = res.data;
                localStorage.setItem('cityData', JSON.stringify(this.cityFilteredData))
            },
                () => {
                });

    }

    onPageLoad() {
        const apis = forkJoin({
            nationality: this.api.getSubscriptionData('academy/getCountries'),
            cities: this.api.getSubscriptionData('academy/getCities')
        });
        apis.subscribe((response: any) => {
            if (response.nationality.statusCode === 200) {
                this.countryData = response.nationality.data;
                this.countryFilteredData = response.nationality.data;
                localStorage.setItem('countryData', JSON.stringify(response.nationality.data))
            }
            if (response.cities.statusCode === 200) {
                this.cityData = response.cities.data;
                localStorage.setItem('cityData', JSON.stringify(this.cityData))
                this.cityFilteredData = response.cities.data;
            }
        });
        this.countryNameList = this.api.countryNameList;
        localStorage.setItem('countryNameList', JSON.stringify(this.countryNameList))
    }
    get f() { return this.form.controls; }
    submit() {
        this.registeredEmail = '';
        this.isSubmitted = true;
        if (this.form.invalid) {
            this.form.markAllAsTouched();
        } else {
            const dataObj = {
                name: this.form.value.name,
                academyName: this.form.value.academyName,
                nationality: this.form.value.country,
                city: this.form.value.city,
                password: this.form.value.password,
                email: this.form.value.email,
                countryCode: this.form.value.countryCode,
                mobileNo: this.form.value.mobileNo
            };
            this.api.postData(this.api.apiUrl.signUp, dataObj)
                .subscribe((response: any) => {
                    // debugger
                    if (response.statusCode === 200) {
                        localStorage.setItem('registeredEmail', response.data.email);
                        this.registeredEmail = response.data.email;
                        //this.router.navigate(['/acadyformd']);
                        this.search.nativeElement.click(); // open Thankyou popup
                        this.form.reset();
                    }
                }, (error: any) => {
                });
        }
    }

    onCountryCodeChange(countryCode) {
        this.digits9 = false;
        this.digits8 = false;
        this.form.controls.mobileNo.patchValue('');
        if (countryCode == '+966') {
            this.form.controls['mobileNo'].setValidators([Validators.pattern("^[0-9]{9}$")]);
            this.digits9 = true;
        }
        else {
            this.form.controls['mobileNo'].setValidators([Validators.pattern("^[0-9]{8}$")]);
            this.digits8 = true;
        }
    }
}
