import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './external/login/login.component';
import {TermsComponent} from './external/terms/terms.component';
import {PrivacyComponent} from './external/privacy/privacy.component';
import {ExternalAuthguardService} from './services/externalAuthguard.service';
import {ReferralComponent} from './external/referral-code/referral.component'; 
import { ForgotComponent } from './external/forgot/forgot.component';
import { AcadyformComponent } from './external/acadyform/acadyform.component';
import { AcadyformdComponent } from './external/acadyformd/acadyformd.component';
import { UcComponent } from './external/uc/uc.component';
import { EmailVerificationComponent } from './external/email-verification/email-verification.component';
import { ResetPSWComponent } from './reset-psw/reset-psw.component';
const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'uc', component: UcComponent},
   
    {path: 'signupform', component: AcadyformComponent},
    {path: 'acadyformd', component: AcadyformdComponent},
    {path: 'login', component: LoginComponent, canActivate: [ExternalAuthguardService]},
    {path: 'forgotPassword', component: ResetPSWComponent, canActivate: [ExternalAuthguardService]},
    {path: 'verification', component: EmailVerificationComponent, canActivate: [ExternalAuthguardService]},
    {path: 'forgot', component: ForgotComponent, canActivate: [ExternalAuthguardService]},
    {path: 'terms', component: TermsComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'referral', component: ReferralComponent},
    {path: '', loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)},
    {path: '**', component: UcComponent},
    {path: '**', component: LoginComponent, canActivate: [ExternalAuthguardService]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})


export class AppRoutingModule {
}
