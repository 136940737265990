import { Color } from 'ng2-charts/lib/color';
import { ajaxGet } from 'rxjs/internal-compatibility';

export const CropSizes = {
    field: { first: 1384, sec: 593 },
    feed: { first: 1307, sec: 692 },
    popup: { first: 1334, sec: 980 }
};

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const ARABIC = '[\u0600-\u06FF]';
// export const ARABIC = '[أ-ي]';
// export const ARABIC = '^[\p{Arabic}\s\p{N}]+$';
export const ENGLISH = '^[a-zA-Z ]+$';

export const AgeGroup = [{ name: 'U 13', value: '13', isSelected: false },
{ name: 'U 14', value: '14', isSelected: false },
{ name: 'U 15', value: '15', isSelected: false },
{ name: 'U 16', value: '16', isSelected: false },
{ name: 'U 17', value: '17', isSelected: false },
{ name: 'U 18', value: '18', isSelected: false },
{ name: 'U 19', value: '19', isSelected: false },
{ name: 'U 20', value: '20', isSelected: false },
{ name: '20 - 24', value: '2024', isSelected: false },
{ name: '24+', value: '24', isSelected: false }
];

export const monthsList = [
{ value: 0, en: 'January', ar: 'يناير' },
{ value: 1, en: 'February', ar: 'فبراير' },
{ value: 2, en: 'March', ar: 'مارس' },
{ value: 3, en: 'April', ar: 'أبريل' },
{ value: 4, en: 'May', ar: 'مايو' },
{ value: 5, en: 'June', ar: 'يونيو' },
{ value: 6, en: 'July', ar: 'يوليو' },
{ value: 7, en: 'August', ar: 'أغسطس' },
{ value: 8, en: 'September', ar: 'سبتمبر' },
{ value: 9, en: 'October', ar: 'أكتوبر' },
{ value: 10, en: 'November', ar: 'نوفمبر' },
{ value: 11, en: 'December', ar: 'ديسمبر' },

];

export const sideBarAdmin = [

     

    { path: '/cmsacadylist', icon: 'fa fa-school', children: [], title: 'Academy', permissions: ['superAdmin', 'queries'] },
    { path: '/cmsacadyreginfo', icon: 'fa fa-school', children: [], title: 'Academy Registration', permissions: ['superAdmin', 'queries'] },
    {
        path: 'settings',
        icon: 'fas fa-tools',
        title: 'Settings',
        permissions: ['superAdmin', 'bugsform.bugsform'],
        children: [
            { path: '/setacademycategory',icon: '',title: 'Academic Category',children: [], permissions: ['superAdmin', 'bugsform.bugsform']},
            { path: '/setnotification',  icon: '', title: 'Notifrication KeyWord', children: [], permissions: ['superAdmin', 'bugsform.bugsform']},
            { path: '/setdefaultmsg',  icon: '', title: 'Default Message', children: [], permissions: ['superAdmin', 'bugsform.bugsform']},
            { path: '/states', icon: '', title: 'States', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
            { path: '/cities', icon: '', title: 'Cities', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
            { path: '/currencies',icon: '', title: 'Currencies', children: [], permissions: ['superAdmin', 'bugsform.bugsform']}      
            
            
           
        ]
    },
    { path: '/sub-admins', icon: 'fa fa-user-secret', title: 'Sub Admins', permissions: ['superAdmin'], children: [] },
    
    {
        path: 'adminSetting',
        icon: 'fas fa-tools',
        title: 'Admin',
        permissions: ['superAdmin', 'bugsform.bugsform'],
        children: [
             
            { path: '/username', icon: '', title: 'Sub Admin', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
            { path: '/setgroups', icon: '', title: 'Groups', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
            { path: '/setrights', icon: '', title: 'Rights', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
            { path: '/setmodules', icon: '', title: 'Modules', children: [], permissions: ['superAdmin', 'bugsform.bugsform'] },
                  
            
            
           
        ]
    },
];
 
export const sideBarStaff = [
    { path: '/academy', icon: 'fa fa-school', children: [], title: 'Academy', permissions: ['superAdmin', 'queries'] },
    { path: '/academyreg', icon: 'fa fa-school', children: [], title: 'Academy Registration', permissions: ['superAdmin', 'queries'] },
];




 

export const GraphColors = [
    { // red
        backgroundColor: 'transparent',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }, { // dark grey
        backgroundColor: 'transparent',
        borderColor: 'rgba(248,172,89,1)',
        pointBackgroundColor: 'rgba(248,172,89,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(248,172,89,1)'
    }

];
export const DonutGraphColors = [
    { // red
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }

];

export const BarGraphColors = [
    { // blue
        backgroundColor: 'rgba(66,201,90,0.2)', //rgba(76,198,200,0.5)
        borderColor: '#d80019',
        pointBackgroundColor: '#d80019',
        pointBorderColor: '#d80019',
        pointHoverBackgroundColor: '#d80019',
        pointHoverBorderColor: '#d80019'
    },

];


