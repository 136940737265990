import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { GlobalLangService } from '../../services/global-lang.service';
@Component({
  selector: 'app-player-filter',
  templateUrl: './player-filter.component.html',
  styleUrls: ['./player-filter.component.css']
})
export class PlayerFilterComponent implements OnInit {
  playerForm: FormGroup;
  primaryPositionData = [];
  secondaryPositionData = [];
  positionData = [];
  citiesData = [];
  nationalities = [];
  ageGroupData: any;
  YobList: any[] = [];
  isYobSupported = false;
  primaryFilter = new FormControl();
  secondaryFilter = new FormControl();
  translate: any;
  listType: any;
  isEnglish = false;
  loggedInUserType: string = '';
  academyResponsibleFor: string = '';
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PlayerFilterComponent>,
    public api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any, private globalService: GlobalLangService) {
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.FILTERPLAYERS) {
        this.translate = data.FILTERPLAYERS;
      }
    });
    this.isEnglish = localStorage.getItem('language') === 'en' ? true : false;
    this.academyResponsibleFor = localStorage.getItem("academyResponsibleFor");
  }

  ngOnInit() {
    this.loggedInUserType = localStorage.getItem('loggedInUserType');
    this.onPageLoad();
    this.primaryFilter.valueChanges.subscribe(() => {
      let search = this.primaryFilter.value.toLowerCase();
      let filteredData = [];
      this.primaryPositionData = [];
      if (search) {
        this.positionData.forEach((element: any) => {
          if (element.en.toLowerCase().indexOf(search) > -1) {
            filteredData.push(element);
          }
        });
        this.primaryPositionData = filteredData;
      }
      else {
        this.primaryPositionData = this.positionData;
      }
    });
    this.secondaryFilter
      .valueChanges
      .pipe()
      .subscribe(() => {
        let search = this.secondaryFilter.value.toLowerCase();
        let filteredData = [];
        this.secondaryPositionData = [];
        if (search) {
          this.positionData.forEach((element: any) => {
            if (element.en.toLowerCase().indexOf(search) > -1) {
              filteredData.push(element);
            }
          });
          this.secondaryPositionData = filteredData;
        }
        else {
          this.secondaryPositionData = this.positionData;
        }
      });
  }

  onPageLoad() {
    this.playerForm = this.fb.group({
      joinDate: [''],
      contractType: [''],
      contractStatus: [''],
      nationality: [''],
      city: [''],
      payType: [''],
      primaryPosition: [''],
      secondaryPosition: [''],
      ageGroup: [''],
      yob: ['']
    });
    this.createForm();
    const positionObj: any = {};
    positionObj.remoteurl = 'academy/getPositions'
    positionObj.methodtype = "GET";
    const nationalitieObj: any = {};
    nationalitieObj.remoteurl = 'academy/getCountries';
    nationalitieObj.methodtype = "GET";
    const citiesObj: any = {};
    citiesObj.remoteurl = 'academy/getCities';
    citiesObj.methodtype = "GET";
    const ageGroupObj: any = {};
    ageGroupObj.pageNo = 1;
    ageGroupObj.acadyid = localStorage.getItem("acadyid");
    ageGroupObj.remoteurl = 'academy/getAgeGroups';
    ageGroupObj.userEmail = localStorage.getItem('emailaddress');
    ageGroupObj.methodtype = "GET";
    const dashboardObj: any = {};
    dashboardObj.remoteurl = 'academy/getDashboard';
    dashboardObj.methodtype = "GET";
    forkJoin(
      {
        positions: this.api.getData(positionObj.remoteurl, true),
        nationalities: this.api.getData(nationalitieObj.remoteurl, true),
        cities: this.api.getData(citiesObj.remoteurl, true),
        ageGroup: this.api.getData(ageGroupObj.remoteurl, true),
        dashboard: this.api.getData(dashboardObj.remoteurl, true)
      }
    ).subscribe((response: any) => {
      if (response.positions) {
        response.positions.data.forEach(element => {
          element.isDisabled = false;
        });
        this.positionData = response.positions.data;
        this.primaryPositionData = response.positions.data;
        this.secondaryPositionData = response.positions.data;
      };
      if (response.nationalities) {
        this.nationalities = response.nationalities.data;
      }
      if (response.cities) {
        this.citiesData = response.cities.data;
      }
      if (response.ageGroup) {
        this.ageGroupData = response.ageGroup.data;
      }
      if (response.dashboard) {
        if (response.dashboard.data.academy[0].ageGroup.length > 0) {
          this.isYobSupported = false;
        }
        else if (response.dashboard.data.academy[0].yob.length > 0) {
          this.isYobSupported = true;
          this.YobList = response.dashboard.data.academy[0].yob;
        }
      }
    });
  }


  createForm() {
    if (this.data.listType) {
      this.listType = this.data.listType;
    }
    if (this.data.joinDate) {
      this.playerForm.patchValue({ joinDate: this.data.joinDate });
    }
    if (this.data.contractType) {
      this.playerForm.patchValue({ contractType: this.data.contractType });
    }
    if (this.data.contractStatus) {
      this.playerForm.patchValue({ contractStatus: this.data.contractStatus });
    }
    if (this.data.nationality) {
      this.playerForm.patchValue({ nationality: this.data.nationality });
    }
    if (this.data.city) {
      this.playerForm.patchValue({ city: this.data.city });
    }
    if (this.data.payType) {
      this.playerForm.patchValue({ payType: this.data.payType });
    }
    if (this.data.primaryPosition) {
      this.playerForm.patchValue({ primaryPosition: this.data.primaryPosition });
    }
    if (this.data.secondaryPosition) {
      this.playerForm.patchValue({ secondaryPosition: this.data.secondaryPosition });
    }
    if (this.data.ageGroup) {
      this.playerForm.patchValue({ ageGroup: this.data.ageGroup });
    }
    if (this.data.yob) {
      this.playerForm.patchValue({ yob: this.data.yob });
    }
  }
  submitPlayer() {
    if (this.playerForm.valid) {
      this.dialogRef.close(this.playerForm.value);
    }
  }
  resetForm() {
    this.playerForm.reset();
    this.dialogRef.close(this.playerForm.value);
  }
  close() {
    this.dialogRef.close(); return false;
  }
}
